import React, { Component } from 'react';
import AboutStyles from './About.module.css';
import Gallery from "react-photo-gallery";
import UtilitiesStyles from './utilities.module.css';
import Carousel, { Modal, ModalGateway } from "react-images";

export const photos = [
  {
    src: '/assets/images/paint4.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/paint1.png',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/paint2.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/paint3.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/paint5.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/3.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/20.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/2.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/29.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/30.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/4.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/24.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/5.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/6.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/7.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/8.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/9.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/21.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/10.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/11.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/12.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/13.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/14.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/15.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/16.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/17.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/18.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/19.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/22.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/23.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/25.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/26.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/27.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/28.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/31.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/33.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/32.jpg',
    width: 4,
    height: 3
  },

  {
    src: '/assets/images/photos/34.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/35.jpg',
    width: 4,
    height: 3
  },
];


class About extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      viewerIsOpen: false
    }
  }
  setCurrentImage = (index) => {
    this.setState({
      currentImage: index
    })
  }

  setViewerIsOpen = (bool) => {
    this.setState({
      viewerIsOpen: bool
    })
  }


  openLightbox = (event, { photo, index }) => {
    this.setCurrentImage(index);
    this.setViewerIsOpen(true);
  };

  closeLightbox = () => {
    this.setCurrentImage(0);
    this.setViewerIsOpen(false);
  };


  render() {
    return (
      <div>
      <div>
      <div className={AboutStyles.container}>
        <div className={AboutStyles.about_container}>
          <div>
                <img className={AboutStyles.picture} src="/assets/images/me.png" alt="Me" />
                <h4><span role="img" aria-label="Medal">🥇</span>{' '}Achievements</h4>
                <div className={AboutStyles.achievement_cont}>
                    <div className={AboutStyles.achi}>
                      <b>Editor's Pick</b>
                      <p>Bestfolio</p>
                    </div>
                    <div className={AboutStyles.achi}>
                      <b>Best Continuation Hack</b>
                      <p>Hack:now 2020</p>
                    </div>
                    <div className={AboutStyles.achi}>
                      <b>Finalist</b>
                      <p>Covid Global Hackathon 2020</p>
                    </div>
                    <div className={AboutStyles.achi}>
                      <b>First Place Winner</b>
                      <p>Adobe XD Dribbble Contest</p>
                    </div>
                    <div className={AboutStyles.achi}>
                    <b>Excellence Award</b>
                    <p>University of Toronto</p>
                      </div>
                    </div>
              </div>
              <div>
              <h3>Hello, I'm Taamannae <span role="img" aria-label="waving hand">👋</span></h3>
              <p style={{marginTop: "0px", color:'#445566cc', fontSize: '18px'}}>/ta•man•ah/</p>

              <h5>I am passionate about integrating <b>mindfulness</b>, <b>empathy</b> & <b>neuroscience</b> in crafting accessible interactions & experiences</h5>

                <p> I am currently a UX Design intern <a href="https://notdev.com/" target="_blank" rel="noopener noreferrer">@NotDev</a> and <a href="https://potluck.io/" target="_blank" rel="noopener noreferrer">@Potluck</a>. I have designed for <a href="https://www.ycombinator.com/" target="_blank" rel="noopener noreferrer">Y Combinator</a> companies such as <a href="https://rally.video/" target="_blank" rel="noopener noreferrer">@Rally</a> and <a href="https://layerci.com" target="_blank" rel="noopener noreferrer">@layerCI</a>. I am also currently an HCI research assistant <a href="https://ricelab.github.io/" target="_blank" rel="noopener noreferrer">@RiceLab</a>. Previously a  UX designer <a href="https://momentcrm.com/" target="_blank" rel="noopener noreferrer">@Moment</a> and software engineering intern <a href="http://ibm.com/" target="_blank" rel="noopener noreferrer">@IBM</a>. I am currently pursuing a <a href="https://ischool.utoronto.ca/areas-of-study/bachelor-of-information/" target="_blank" rel="noopener noreferrer">Bachelor of Information</a> at the University of Toronto. I have already received my HBSc in Neuroscience, Computer Science and Statistics.
              I love working on puzzles and projects that keep me on my toes in order to challenge myself and grow as a designer & developer.
            </p>
                  <div style={{ marginBottom: '10px' }}>
                    <li>I have 7 a's in my full name</li>
                    <li>I've been designing since I was 10, when I taught myself HTML/CSS and Photoshop</li>
                    <li>I've been to 9 countries</li>
                    <li>I can knit & sew</li>
                  </div>
                  <p>When I'm not trying to solve puzzles, I <span role="img" aria-label="video game control">🎮</span>play video games, <span role="img" aria-label="Plane">✈️</span>travel, <span role="img" aria-label="books">📚</span>read, 🎨paint, ✏️illustrate, take 📷photographs of the world around me and write on <a href="https://medium.com/@taamannae" target="_blank" rel="noopener noreferrer">Medium</a>. </p>
                  <p>Curious about why I started designing at 10 years old or what I've been up to? Feel free to email me!</p>
                  <a href="mailto:taamannae@gmail.com" target="_blank" rel="noopener noreferrer" className={UtilitiesStyles.button}>
                    Email me!</a>
              </div>
             
          </div>
        </div>


      </div>
        <Gallery photos={photos} direction={"column"} onClick={this.openLightbox} id="lightbox"/>
        <ModalGateway>
          {this.state.viewerIsOpen ? (
            <Modal onClose={this.closeLightbox}>
              <Carousel
                currentIndex={this.state.currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>

      </div>
    );
  }
}

export default About;
