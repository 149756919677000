import React, { Component } from "react";
import ProgressStyles from "./Progress.module.css";

export default class Progress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      position: '0%',
      height: 0
    }
  }
  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
    var html = document.documentElement;
    this.setState({
      height: html.scrollHeight,
    })


  }

  handleScroll = (e) => {
    var html = document.documentElement;
    let position = (window.pageYOffset / (html.scrollHeight - 900));
    this.setState({
      position:  position * 100 + '%'
    })
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll');
  }

  render() {
    return (
      <div className={ProgressStyles.progress}>
        <div className={ProgressStyles.progress_inner} style={{width: this.state.position}}/>
      </div>
    );
  }
}
