import React, { Component } from 'react';
import classnames from 'classnames';
import ScheduStyles from './Schedu.module.css';
import Projects from './Projects';
import ProjectInfo from './ProjectInfo';
import ProjectStyles from './Projects.module.css';
import ImageZoom from 'react-medium-image-zoom';
import UtilitiesStyles from '../utilities.module.css';
import LazyLoad from 'react-lazy-load';
import Slider from "react-slick";
import Nav from '../Nav';
import Progress from './Progress';
import './Slick.css';

export default class Schedu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    };
    this.nav = React.createRef()
  }


  render() {
    const lofi = {
      customPaging: function(i) {
        return (
          <span className={ScheduStyles.cara_img}>
            <img src={`/assets/images/1x/lofi${i}.png`} alt="Low Fidelity Issues and Solutions" width="100%"/>
          </span>
        );
      },
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const mifi = {
      customPaging: function(i) {
        return (
          <span className={ScheduStyles.cara_img}>
            <img src={`/assets/images/1x/mifi${i}.png`} alt="Mid Fidelity Issues and Solutions" width="100%"/>
          </span>
        );
      },
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <Projects
        img="/assets/images/schedu.webp"
        title="Schedu"
        color="#4189FF"
        roleList={['UX Designer']}
        tools={['xd', 'ai', 'pen']}
        people={<p>Me (Team Leader), Simrit Khabra, Erxun Ta, Zhongdi Fu</p>}

        timeline="Sep 2019 - Dec 2019"
        subtitle="A mobile app that helps students lessen time management cognitive load, finish assignments on time and reschedules tasks when they can't work"
        size="80%">
        <div id="overview">

        <ProjectInfo
          title="Schedu"
          timeline="Sep 2019 - Dec 2019"
          process="/assets/images/process12.png"
          problem="How might we help busy students get on top of their time management?"
          outcome="A mobile app that helps students lessen time management cognitive load, finish assignments on time and reschedules tasks when they can't work"
          description={(<p>This project was done for a school project with 3 other students. Arguably, one of the biggest problems plaguing students is time management and the stress that goes with it. In the first week of class, before the project started had started, I heard one of my classmates say <b>"Ugh, I forgot that was due. I need to start an agenda".</b> This sparked the interest to explore the problem further. Initially, I had assumed that students were having a hard time planning their schedule. My group and I tried to validate this idea by using the double diamond design process.</p>)}
       />
        </div>

        <div id="research" className={ProjectStyles.basic_margin}>
        <div className={ProjectStyles.title_flex}>
            <h6>Talking to People</h6>

            <h1>User Research</h1>
        </div>
        <p>My team and I conducted <b>10 semi structured interviews, 1 diary study and we had 64 students fill out our survey. </b> These are our key findings.</p>
        
        <div className={classnames(UtilitiesStyles.d_flex, UtilitiesStyles.text_center, ScheduStyles.nums)}>
          <div className={classnames(UtilitiesStyles.flex_one, ScheduStyles.flex_one)}>
          <div style={{width: 100, margin: '30px auto'}}>
          </div>
            <h3 className={ScheduStyles.stat}>52%</h3>
            <p className={ScheduStyles.research}>Of surveyed students start assignment a couple days to a week in advance (33 of 64)</p>
          </div>
          <div className={classnames(UtilitiesStyles.flex_one, ScheduStyles.flex_one)}>
            <div style={{width: 100, margin: '30px auto'}}>
            </div>
            <h3 className={ScheduStyles.stat}>79%</h3>
            <p className={ScheduStyles.research}>Of surveyed students feel anxious about time management multiple times a week (32 of 64)</p>
          </div>
  
          <div className={classnames(UtilitiesStyles.flex_one, ScheduStyles.flex_one)}>
            <div style={{width: 100, margin: '30px auto'}}>
            </div>
            <h3 className={ScheduStyles.stat}>82%</h3>
            <p className={ScheduStyles.research}>Of surveyed students modify short-term goals according to the demands (52 of 64)</p>
          </div>
  
        
        </div>
        <ImageZoom
            image={{
              src: '/assets/images/whystress.png',
              className: 'img',
              style: { width: '100%', marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/whystress.png',
            }}
          />
        </div>
        <div id="interpretation" className={ProjectStyles.basic_margin}>
        <div className={ScheduStyles.container}>
          <div className={ProjectStyles.title_flex}>
              <h6>Understanding the User</h6>
              <h1>Interpretation</h1>
          </div>
          <p>With the collected data, we created an affinity map to better understand the key issues students were facing. </p>
          <ImageZoom
              image={{
                src: '/assets/images/affinitymap.jpg',
                className: 'img',
                style: { width: '100%', marginBottom: 50 }
              }}
              zoomImage={{
                src: '/assets/images/affinitymap.jpg',
              }}
            />
          <p>The main issues we saw were <b>prioritization</b> and <b>unexpected time sinks</b>, followed closely by <b>fear of failure</b>. I created a bubble chart to visualize the priority of the issues.</p>
          <div className={UtilitiesStyles.text_center}>
          <ImageZoom
              image={{
                src: '/assets/images/keyissues.png',
                className: 'img',
                style: { width: '50%', margin: '50px auto' }
              }}
              zoomImage={{
                src: '/assets/images/keyissues.png',
              }}
            />
          </div>

          <p>Our team worked together to create a <b>persona, and a journey map</b> to explore the needs, frustrations and goals of our target user. Stressed Sonia Syed represents the students we interviewed. She wants to get good grades and complete assignments on time but sometimes other obligations get in the way.</p>

          <ImageZoom
              image={{
                src: '/assets/images/schpersona.png',
                className: 'img',
                style: { width: '50%', margin: '50px auto' }
              }}
              zoomImage={{
                src: '/assets/images/schpersona.png',
              }}
            />
          <ImageZoom
              image={{
                src: '/assets/images/schjourney.png',
                className: 'img',
                style: { width: '50%', margin: '50px auto' }
              }}
              zoomImage={{
                src: '/assets/images/schjourney.png',
              }}
            />
          
          <p style={{marginTop: '50px'}}> We realized that our initial assumption was somewhat incorrect. <b>Students like Sonia were not struggling with making schedules. They were struggling with maintaining schedules when life got in the way and tasks took longer than expected.</b>  It resulted in rushing to finish assignments. The persona and journey map helped lead us to the following questions. Moving forward, we used them as our guide and main problems to solve for the user.</p>
          <div className={classnames(UtilitiesStyles.d_flex, ScheduStyles.insight_cont)}>
              <div className={classnames(UtilitiesStyles.flex_1, ScheduStyles.insight)}>
              <p>How might we reduce Sonia’s cognitive load while planning a schedule? </p>
              </div>
              <div className={classnames(UtilitiesStyles.flex_1, ScheduStyles.insight)}>
              <p>How might we help Sonia adapt her schedule to fit her shifting life?</p>
              </div>
              <div className={classnames(UtilitiesStyles.flex_1, ScheduStyles.insight)}>
              <p>How might we help Sonia finish tasks on time?</p>
              </div>
            </div>
          </div>
        </div>

        <div id="ideation" className={ProjectStyles.basic_margin}>
        <div className={ProjectStyles.title_flex}>
          <h6>Exploring Options</h6>

          <h1>Ideation</h1>
        </div>
        <p>Our team ideated separately before coming together and grouping solutions into appropriate sections. Once we had ideas, we voted on the ideas and created a prioritization grid.</p>
        <ImageZoom
            image={{
              src: '/assets/images/idea.png',
              className: 'img',
              style: { width: '50%', margin: '50px auto' }
            }}
            zoomImage={{
              src: '/assets/images/idea.png',
            }}
          />

        <ImageZoom
            image={{
              src: '/assets/images/prio.png',
              className: 'img',
              style: { width: '50%', margin: '50px auto' }
            }}
            zoomImage={{
              src: '/assets/images/prio.png',
            }}
          />

        <p>Based on our ideation, we felt the best way to solve issues would be the following</p>
        <div className={classnames(UtilitiesStyles.d_flex, ScheduStyles.insight_cont)}>
            <div className={classnames(UtilitiesStyles.flex_1, ScheduStyles.idea)}>
            <h5>1. Notification Nudges</h5>
            <p>Nudge students when to start working or if they get distracted</p>
            <p><b>This will help students:</b> Finish tasks on time</p>
            </div>
            <div className={classnames(UtilitiesStyles.flex_1, ScheduStyles.idea)}>
            <h5>2. Historic Data</h5>
            <p>Collect historic data from other UofT students on assignment completion time to create a schedule for them</p>
            <p><b>This will help students:</b> Reduce cognitive load while planning</p>
            </div>
            <div className={classnames(UtilitiesStyles.flex_1, ScheduStyles.idea)}>
            <h5>3. Automatic Reschedule</h5>
            <p>If the student says they can't work that day or they forget, the app will automatically reschedule time to do the assignment for them</p>
            <p><b>This will help students:</b> Reduce cognitive load while planning, Adapt schedule to shifting life</p>
            </div>
            <div className={classnames(UtilitiesStyles.flex_1, ScheduStyles.idea)}>
            <h5>4. Breakdown Syllabus and Assignments</h5>
            <p>Automatically parse syllabus we collect by connecting to the student Canvas app and allow the user to set goals based on assignments to make tasks seem less daunting</p>
            <p><b>This will help students:</b> finish tasks on time, Reduce cognitive load while planning</p>
            </div>
          </div>

          <p style={{marginTop: 50}}>I created a user flow for how we expected the student to use the application based on the predicted happy path. I also designed how I expected the information architecture to be. </p>

          <ImageZoom
            image={{
              src: '/assets/images/userflow.jpg',
              className: 'img',
              style: { width: '50%' }
            }}
            zoomImage={{
              src: '/assets/images/userflow.jpg',
            }}
          />

          <ImageZoom
            image={{
              src: '/assets/images/ia.jpg',
              className: 'img',
              style: { width: '50%' }
            }}
            zoomImage={{
              src: '/assets/images/ia.jpg',
            }}
          />
        </div>
        
        <div id="lofi" className={ProjectStyles.basic_margin}>

        <div className={ProjectStyles.title_flex}>
          <h6>Testing Solutions</h6>

          <h1>Prototyping</h1>
        </div>
        <p>We knew we had to make an app because students would access this tool constantly, even when they don't have a computer near by. We created low fidelity wire frames using pencil and paper which we tested on 5 people. We were aiming to get some early feedback on interaction and thoughts about the idea of the app.</p>

        <ImageZoom
            image={{
              src: '/assets/images/lofi.png',
              className: 'img',
              style: { width: '100%' }
            }}
            zoomImage={{
              src: '/assets/images/lofi.png',
            }}
          />

        <div className={ProjectStyles.title_flex} style={{marginBottom: 0}}>
          <h1>Results</h1>
        </div>
          <div className={ScheduStyles.slider}>

        <Slider {...lofi} >
          <div>
          <ImageZoom
            image={{
              src: '/assets/images/1x/lofi0.png',
              className: 'img',
              style: { width: '100%',  marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/1x/lofi0.png',
            }}
          />
          </div>
          <div>
          <ImageZoom
            image={{
              src: '/assets/images/1x/lofi1.png',
              className: 'img',
              style: { width: '100%',  marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/1x/lofi1.png',
            }}
          />
          </div>
          <div>
          <ImageZoom
            image={{
              src: '/assets/images/1x/lofi2.png',
              className: 'img',
              style: { width: '100%',  marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/1x/lofi2.png',
            }}
          />
          </div>
          <div>
          <ImageZoom
            image={{
              src: '/assets/images/1x/lofi3.png',
              className: 'img',
              style: { width: '100%',  marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/1x/lofi3.png',
            }}
          />
          </div>

        </Slider>
        </div>
        </div>

        <div id="mifi" className={ProjectStyles.basic_margin}>
          <div className={ProjectStyles.title_flex} style={{marginBottom: 0}}>
            <h6>Getting Closer to the truth</h6>

            <h1>Mid-Fi Prototype</h1>
          </div>

          <p>Then we created a mid fidelity wire frame that was tested on 5 more people. This time, we gave users 3 tasks to complete and we tested for interaction and information architecture. All users were able to complete all the tasks but there were a few issue along the way. </p>
          <ImageZoom
              image={{
                src: '/assets/images/mifi.png',
                className: 'img',
                style: { width: '100%', marginTop: 50 }
              }}
              zoomImage={{
                src: '/assets/images/mifi.png',
              }}
            />
          <div className={ProjectStyles.title_flex} style={{marginBottom: 0}}>
            <h1>Results</h1>
          </div>
          <div className={ScheduStyles.slider}>

          <Slider {...mifi} >
            <div>
            <ImageZoom
              image={{
                src: '/assets/images/1x/mifi0.png',
                className: 'img',
                style: { width: '100%', marginTop: 50 }
              }}
              zoomImage={{
                src: '/assets/images/1x/mifi0.png',
              }}
            />
            </div>
            <div>
            <ImageZoom
              image={{
                src: '/assets/images/1x/mifi1.png',
                className: 'img',
                style: { width: '100%', marginTop: 50 }
              }}
              zoomImage={{
                src: '/assets/images/1x/mifi1.png',
              }}
            />
            </div>
            <div>
            <ImageZoom
              image={{
                src: '/assets/images/1x/mifi2.png',
                className: 'img',
                style: { width: '100%', marginTop: 50 }
              }}
              zoomImage={{
                src: '/assets/images/1x/mifi2.png',
              }}
            />
            </div>
            <div>
            <ImageZoom
              image={{
                src: '/assets/images/1x/mifi3.png',
                className: 'img',
                style: { width: '100%', marginTop: 50 }
              }}
              zoomImage={{
                src: '/assets/images/1x/mifi3.png',
              }}
            />
            </div>

          </Slider>

        </div>
        </div>
        <div id="hifi" className={ProjectStyles.basic_margin}>

        <div className={ProjectStyles.title_flex}>
            <h6>Final Result</h6>

          <h1>High-fidelity Prototype</h1>
        </div>
        <div className={classnames(UtilitiesStyles.d_flex, ScheduStyles.videos)}>
          <div className={classnames(UtilitiesStyles.flex_1, UtilitiesStyles.text_center)} style={{margin: 20}}>
                <img src="/assets/images/onb1.gif" alt="Onboarding flow"width="100%"/>            

          <h5 style={{margin: '20px auto 0px'}}>On-boarding</h5>
          <p>The on-boarding will ask a series of questions to understand the user's current habits such as sleep times and break length so we can create personal schedule for them.</p>
          </div>
          <div className={classnames(UtilitiesStyles.flex_1, UtilitiesStyles.text_center)} style={{margin: 20}}>
                <img src="/assets/images/doing1.gif" alt="Working on assignment flow" width="100%"/>            
          <h5 style={{margin: '20px auto 0px'}}>Doing assignments</h5>
          <p>Users have study session planned for them that they can do or skip and the app will automatically reschedule. Users complete tasks by checking off the subtasks we parse from assignments.</p>
          </div>
          <div className={classnames(UtilitiesStyles.flex_1, UtilitiesStyles.text_center)} style={{margin: 20}}>
          
                <img src="/assets/images/distract.gif" alt="Distraction flow" width="100%"/>

          <h5 style={{margin: '20px auto 0px'}}>Getting Distracted</h5>
          <p>In order to help finish tasks on time, we have to help them when they get distracted. By tracking how long they spend not working, we can nudge them and make sure they get back on track.</p>
          </div>
        </div>
        </div>
        <div id="interactive" className={ProjectStyles.basic_margin}>
        <div className={ProjectStyles.title_flex}>
            <h6>Try it out</h6>

          <h1>Interactive Prototype</h1>
        </div>
        <p>I created this prototype by using Adobe XD. You can interact with it to see how it works! Adobe XD might load the screens incorrectly so you may have to go through the interaction twice to see it properly. (After going through it once, go to the calendar and then back home to restart) </p>
        <div className={UtilitiesStyles.text_center}>
        <LazyLoad height={670} offsetVertical={300}>
        <iframe title="Schedu Adobe XD High Fidelity Prototype" importance="low" loading="lazy" className={UtilitiesStyles.prototype} width="300" height="640" src="https://xd.adobe.com/embed/237456d9-3f6d-435c-46e5-e1f483d3d324-5545"/>
       </LazyLoad>
        <p>Or click below to see it in another tab</p>
        
        <a href="https://xd.adobe.com/embed/237456d9-3f6d-435c-46e5-e1f483d3d324-5545" target="_blank" rel="noopener noreferrer" className={UtilitiesStyles.button}>
          High Fidelity Prototype</a>

        </div>
        </div>
        <div id="reflect" className={ProjectStyles.basic_margin}>
        <div className={ProjectStyles.title_flex}>
            <h6>Taking it further</h6>

          <h1>Next Steps</h1>
        </div>

        <p><b>1. Test for long term use:</b> This app is targeted to help students over a long period of time. We did quick tests that assessed usability. For next time, we should create an active prototype so we can test for long term use.</p>

        <p><b>2. Address mistrust:</b> The users we tested were not sure how well the app knew them. They were afraid to trust the product. For next time, we need to improve user's understanding of how we create schedules.</p>

        <div className={ProjectStyles.title_flex}>
          <h6>How to improve</h6>
          <h1>Lessons Learned</h1>
        </div>

        <p><b>1. You can't please everyone:</b> We had some users who felt the app was fantastic and wished it was real. We also had users who felt it was "capitalistic" and not helpful and that is okay as long as we help solve a problem for the target set of users. Then maybe we try to solve the problem for the others in a different way.</p>

        <p><b>2. Rely on your team and work together:</b> By working with other people, you are less likely to be biased. My team mates provided cool and interesting ideas that I had not considered before. This led to a better product overall. </p>
        </div>
        <Nav items={['overview', 'research', 'interpretation', 'ideation', 'lofi', 'mifi', 'hifi', 'interactive', 'reflect'] }>
           <li><a href="#overview">Overview</a></li>
            <li><a href="#research">Research</a></li>
            <li><a href="#interpretation">Interpretation</a></li>
            <li><a href="#ideation">Ideation</a></li>
            <li><a href="#lofi">LoFi Wireframes</a></li>
            <li><a href="#mifi">MidFi Prototype</a></li>
            <li><a href="#hifi">HiFi Prototype</a></li>
            <li><a href="#interactive">Interactive</a></li>
            <li><a href="#reflect">Reflection</a></li>
        </Nav>
        <Progress/>
    </Projects>





    );
  }
}

