import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import Old from './Old';
import MyProcess from './MyProcess';
import Illustrations from './Illustrations';
import DaysType from './DaysType';
import Misc from './Misc';
import UIChall from './UIChall';
import Publications from './Publications';
import Vaxx from './projects/Vaxx';
import UofTHacks from './projects/UofTHacks';
import Potluck from './projects/Potluck';
import Walden from './projects/Walden';
import Parsehub from './projects/Parsehub';
import Moment from './projects/Moment';
import Undraw from './projects/Undraw';
import Schedu from './projects/Schedu';
import Mento from './projects/Mento';
import Wish from './projects/Wish';
import InsturaFull from './projects/InsturaFull';
import About from './About';
import NotFound from './NotFound';
import WebDesign from './WebDesign';


function AppRouter() {
  return (
    <Router>
      <Header />

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/old" exact component={Old} />
        <Route path="/process" exact component={MyProcess} />
        <Route path="/illustrations" exact component={Illustrations} />
        <Route path="/publications" exact component={Publications} />
        <Route path="/webdesign" exact component={WebDesign} />
        <Route path="/36daysoftype" exact component={DaysType} />
        <Route path="/misc" exact component={Misc} />
        <Route path="/uichall" exact component={UIChall} />
        <Route path="/projects/vaxx" component={Vaxx} />
        <Route path="/projects/potluck" component={Potluck} />
        <Route path="/projects/uofthacks" component={UofTHacks} />
        <Route path="/projects/walden" component={Walden} />
        <Route path="/projects/schedu" component={Schedu} />
        <Route path="/projects/instura" component={InsturaFull} />
        <Route path="/projects/parsehub" component={Parsehub} />
        <Route path="/projects/moment" component={Moment} />
        <Route path="/projects/undraw" component={Undraw} />
        <Route path="/projects/mento" component={Mento} />
        <Route path="/projects/wish" component={Wish} />
        <Route path="/about" component={About} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default AppRouter;
