import React, { Component } from 'react';
import Projects from './Projects';
import ProjectInfo from './ProjectInfo';


export default class Mento extends Component {
  render() {
    return (
      <Projects
        img="/assets/images/mento.webp"
        size={'30%'}
        roleList={['UX Designer']}
        title="Google UX Design Challenge"
        color="#1A2B52"
        tools={['xd', 'ai', 'pen']}
        timeline="6 Days"
        subtitle="My Google 2020 UX Design Challenge. I matched with the YouTube Gaming team and made it to the offer review stage of the process. Unfortunately, due to COVID-19 the internship was canceled.">
      <ProjectInfo
          title="Mento"
          timeline="6 Days"
          description="I applied for the Google 2020 UX Design Internship. This design challenge was part of the interview process. I completed the challenge in 6 days. Although it was challenging given the timeline, I had a lot of fun pushing my limits and addressing an interesting problem space"
          problem="Thank you for the interest! Unfortunately, I cannot share this work out of respect for Google's design hiring process. 
"
          outcome="I matched with the YouTube Gaming team and made it to the offer review stage of the process. Unfortunately, due to COVID-19 the internship was canceled."
       />
    </Projects>
    );
  }
}

