import React, { Component } from 'react';
import DaysTypeStyles from './DaysType.module.css';
import ImageZoom from 'react-medium-image-zoom';
import ProjectStyles from './projects/Projects.module.css'


class DaysType extends Component {
  constructor(props){
    super(props)
    this.state = {
      filters:[]
    }
  }


  render() {
    return (
      <div className={DaysTypeStyles.project_container}>
      <div className={ProjectStyles.title_flex}>
      </div>
      <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/9.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/9.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/8.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/8.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/7.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/7.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/6.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/6.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/5.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/5.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/4.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/4.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/3.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/3.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/2.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/2.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/1.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/1.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/0.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/0.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/z.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/z.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/y.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/y.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/x.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/x.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/w.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/w.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/v.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/v.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/u.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/u.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/t.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/t.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/s.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/s.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/r.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/r.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/q.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/q.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/p.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/p.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/o.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/o.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/n.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/n.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/m.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/m.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/l.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/l.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/k.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/k.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/j.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/j.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/i.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/i.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/h.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/h.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/g.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/g.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/f.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/f.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/e.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/e.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/d.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/d.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/c.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/c.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/b.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/b.png',
            }}
          />
        <ImageZoom
            image={{
              src: '/assets/images/36daysoftype/a.png',
              className: DaysTypeStyles.img,
            }}
            zoomImage={{
              src: '/assets/images/36daysoftype/a.png',
            }}
          />
      </div>
    );
  }
}

export default DaysType;
