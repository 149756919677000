import React from 'react';
import Fade from 'react-reveal/Fade';
import Icons from '../icons.js';

export default ({
    theme,
    icon,
    delay,
    label
}) => {
    return (
        <Fade right delay={delay} duration={400}>
            <span className={ theme['menu-item'] } >
                <img src={Icons[icon]} width={20} className={theme['menu-item-i']} alt={icon}/>
            </span>
        </Fade>
)};
