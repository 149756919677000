import React, { Component } from 'react';
import classnames from 'classnames';
import PublicationsStyles from './Publications.module.css';


class Publications extends Component {
  constructor(props){
    super(props)
    this.state = {
      filters:[]
    }
  }


  render() {
    return (
      <div className={PublicationsStyles.project_container}>
        <a className={PublicationsStyles.item} target="_blank" href="https://uxdesign.cc/figma-design-thinking-building-a-design-system-for-an-existing-product-388bfdeb844b" rel="noopener noreferrer">
          <div className={classnames(PublicationsStyles.item_inner, PublicationsStyles.info)}>
          <div className={PublicationsStyles.avatar}><img src="/assets/images/me.png" alt="Author"/></div>
            <div className={PublicationsStyles.date}>Sept 7 2020</div>
            <div className={PublicationsStyles.title}>Figma & Design Thinking: Building a Design System for an Existing Product</div>
            <div className={PublicationsStyles.subtitle}>When I joined Potluck as a UX Design intern, we were using Sketch, Craft, and Invision for our design-to-development pipeline.</div>
          </div>
          <div className={PublicationsStyles.item_inner} style={{backgroundImage: "url(/assets/images/figma.png)"}}>
          </div>
        </a>
        <a className={PublicationsStyles.item} target="_blank" href="https://medium.com/@taamannae/losing-a-google-ux-design-internship-to-covid-and-what-i-did-about-it-a774d270c6b9" rel="noopener noreferrer">
          <div className={classnames(PublicationsStyles.item_inner, PublicationsStyles.info)}>
          <div className={PublicationsStyles.avatar}><img src="/assets/images/me.png" alt="Author"/></div>
            <div className={PublicationsStyles.date}>June 1 2020</div>
            <div className={PublicationsStyles.title}>Losing a Google UX Design Internship to COVID and What I Did About it</div>
            <div className={PublicationsStyles.subtitle}>I remember when I applied for the internship. I kept refreshing the page until the application button went live.</div>
          </div>
          <div className={PublicationsStyles.item_inner} style={{backgroundImage: "url(/assets/images/googleloss.jpg)"}}>
          </div>
        </a>


        <a className={PublicationsStyles.item} target="_blank" href="https://uxdesign.cc/neumorphism-visual-accessibility-and-empathy-d1c5ed2a1f03" rel="noopener noreferrer">
          <div className={classnames(PublicationsStyles.item_inner, PublicationsStyles.info)}>
          <div className={PublicationsStyles.avatar}><img src="/assets/images/me.png" alt="Author"/></div>
            <div className={PublicationsStyles.date}>May 13 2020</div>
            <div className={PublicationsStyles.title}>Neumorphism, visual accessibility, and empathy</div>
            <div className={PublicationsStyles.subtitle}>Taking an empathic look at why Neumorphism is bad for accessibility and usability.</div>
          </div>
          <div className={PublicationsStyles.item_inner} style={{backgroundImage: "url(/assets/images/neu.jpg)"}}>
          </div>
        </a>

        <a className={PublicationsStyles.item} target="_blank" href="https://uxdesign.cc/expectations-vs-realities-ux-design-in-the-industry-vs-ux-design-at-school-51851e59a5eb" rel="noopener noreferrer">
          <div className={classnames(PublicationsStyles.item_inner, PublicationsStyles.info)}>
          <div className={PublicationsStyles.avatar}><img src="/assets/images/me.png" alt="Author"/></div>
            <div className={PublicationsStyles.date}>Oct 23 2019</div>
            <div className={PublicationsStyles.title}>Expectations vs Realities: UX design in the industry vs UX design at school</div>
            <div className={PublicationsStyles.subtitle}>If you’ve been to design school or taken a design course or even researched UX design, you’ll know how important the design process is</div>
          </div>
          <div className={PublicationsStyles.item_inner} style={{backgroundImage: "url(/assets/images/expect.jpg)"}}>
          </div>
        </a>
      </div>
    );
  }
}

export default Publications;
