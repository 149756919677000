import React, { Component } from 'react';
import UIChallStyles from './UIChall.module.css';
import ImageZoom from 'react-medium-image-zoom';
import ProjectStyles from './projects/Projects.module.css'


class UIChall extends Component {
  constructor(props){
    super(props)
    this.state = {
      filters:[]
    }
  }


  render() {
    return (
      <div className={UIChallStyles.project_container} style={{textAlign: 'center'}}>
      <div className={ProjectStyles.title_flex}>
      </div>
      <ImageZoom
        image={{
          src: '/assets/images/dail11.png',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/dail11.png',
        }}
      />
      <ImageZoom
        image={{
          src: '/assets/images/daily010.gif',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/daily010.gif',
        }}
      />
      <ImageZoom
        image={{
          src: '/assets/images/covidcon.png',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/covidcon.png',
        }}
      />
      <ImageZoom
        image={{
          src: '/assets/images/daily009.gif',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/daily009.gif',
        }}
      />
      <ImageZoom
        image={{
          src: '/assets/images/daily008.png',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/daily008.png',
        }}
      />
      <ImageZoom
        image={{
          src: '/assets/images/daily007.png',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/daily007.png',
        }}
      />
      <ImageZoom
        image={{
          src: '/assets/images/layer.png',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/layer.png',
        }}
      />
        <ImageZoom
          image={{
            src: '/assets/images/DribbbleContest.gif',
            className: 'img',
            style: { width: '50%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/images/DribbbleContest.gif',
          }}
        />
      <ImageZoom
        image={{
          src: '/assets/images/dailyui006.gif',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/dailyui006.gif',
        }}
      />
        <ImageZoom
          image={{
            src: '/assets/images/005.png',
            className: 'img',
            style: { width: '50%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/images/005.png',
          }}
        />

      <ImageZoom
        image={{
          src: '/assets/images/calc.gif',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/calc.gif',
        }}
      />
      <ImageZoom
        image={{
          src: '/assets/images/day3.gif',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/day3.gif',
        }}
      />
        <ImageZoom
          image={{
            src: '/assets/images/002.gif',
            className: 'img',
            style: { width: '50%', marginBottom: '0px' }
          }}
          zoomImage={{
            src: '/assets/images/002.gif',
          }}
        />
      <ImageZoom
        image={{
          src: '/assets/images/001.png',
          className: 'img',
          style: { width: '50%', marginBottom: '0px' }
        }}
        zoomImage={{
          src: '/assets/images/001.png',
        }}
      />
      
      </div>
    );
  }
}

export default UIChall;
