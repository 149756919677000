import React, { Component } from 'react';
import ProjectStyles from './Projects.module.css'
import ImageZoom from 'react-medium-image-zoom';
import Projects from './Projects';
import ProjectInfo from './ProjectInfo';
import Nav from '../Nav';
import Progress from './Progress';


class Vaxx extends Component {
  render() {
    return (
      <Projects
        img="/assets/images/prodmock.webp"
        size="90%"
        title="VAXX"
        color="#14ECCE"
        tools={['pen', 'ai', 'inv']}
        roleList={['UX Designer']}

        timeline="Jan 2018 - Mar 2018"
        subtitle="Vaxx is a tool to help people be more comfortable with vaccines. By familiarizing foreign ingredients with things that can be found near by."
        
       >
      <div id="overview">
       <ProjectInfo
          title="VAXX: Vaccine Application"
          roles="UI & UX Design"
            problem="How might we help more anti vaccine individuals to be comfortable with vaccines"
            outcome="Vaxx is a tool to help people be more comfortable with vaccines. By familiarizing foreign ingredients with things that can be found near by."
          timeline="Jan 2018 - Mar 2018"
          roleList={['Project Manager', 'UX Researcher', 'UI Designer']}
          description={(<p>According to the CDC, the exemption rate for one or more vaccines was 1.9% in 2015-2016. The following year, it rose to 2% and during the 2017-2018 year, it was 2.2%. We thought there was a curious incline and wanted to explore it.</p>)}
       />
       </div>

        <div id="research" className={ProjectStyles.basic_margin}>
        <p></p>
        <div className={ProjectStyles.title_flex}>
          <h5>literature research</h5>
          <h1>Research Phase</h1>
        </div>
        <div>
          <p>A study done by the <a href="http://www.people-press.org/2015/02/09/83-percent-say-measles-vaccine-is-safe-for-healthy-children/">
              Pew Research Center in 2015
              </a> on this topic showed some interesting opinions.
          </p>
          <ul>
            <li><i>"Because you are injecting a virus into a healthy kid, and I don't understand why"</i><b>- Woman aged 39</b>
            </li>
            <li><i>"Because they're injecting you with a disease to prevent a disease" </i> <b>- Woman aged 35</b></li>
            <li><i>"One of my children got a very high fever from the immunization"</i> <b>- Woman aged 57</b></li>
            <li><i>"They make them sicker, it weakens the immune system, instead of allowing the body to use its own natural antibodies to fight it off" </i> <b>- Woman aged 22</b></li>
            <li><i>"Some children cannot take the vaccines. They cause autism and other problems, muscular problems occasionally, but not very often" </i> <b>- Man aged 66</b></li>
          </ul>
          <p> Some studies showed that sources of information made an importance when it came to decision to vaccinate or not.</p>
          <div className="quote">
                Parents of unvaccinated children obtain information
              mostly from TV/radio (44%
              versus 37%),
              newspaper/magazine (30.9%
              versus 22.4%), friends (14.2%
              versus 8.6%) and internet
              (3.6% versus 1.7%) than
              parents of vaccinated children.

              <p/>
              Parents of unvaccinated
              children less likely obtain
              information from medical
              professionals (17.9%) than
              parents of vaccinated children
              (38%)

          <p>- <a href="http://publichealthmy.org/ejournal/ojs2/index.php/ijphcs/article/viewFile/125/114">SOURCES OF INFORMATION AND THEIR IMPACT
          ON PARENTS' VACCINATION DECISIONS</a></p>
          </div>
        </div>

        <div className={ProjectStyles.title_flex}>
          <h5>User Research</h5>
        </div>
        <div>
          <p>We joined a multitude of Facebook groups and collected information in 3 main ways. First simply by watching how indiviuals interacted with one another, what language they used and what kind of pictures they would share. 
            The second way of collection data was by posting a survey that asked very general questions about vaccinations. Finally, we approached some people within these groups and asked if we could interview them. 
          </p>

          <p>We found the following</p>
          <ul>
            <li>There was a lot of misinformation being spread around within these groups. Images were shared that didn't make sense scientifically.</li>
            <li>There was quite a bit of misinterpretation of ingedients. People who felt like ingredients were dangerous.</li>
            <li>A lot of the anti-vaxxers were just new parents who wanted what was best for thier children and weren't sure what that was</li>
            <li>Some Pro-vaccination individuals with science degrees liked to explain to others the science behind vaccines, which they said helped change the minds of the 
              anti-vaccinate individuals </li>
          </ul>
        </div>
        
        </div>
          
        <div id="interpretation" className={ProjectStyles.basic_margin}>
          <h1>interpretation</h1>
            <p>Based on the data we collected, we created personas we felt best represented the target audience. One who is an anti-vaxxer and just wants the best for her children and one who was an anti-vaxx and turned into a pro vaxx. We also created a journey map.
          </p>
          <img src="/assets/images/expmap.png" width="100%" alt="experience map"/>


        <div className={ProjectStyles.persona}>



          <div>
            <h5>Sarah Somers, anti-vaccination turned pro-vaccination</h5>
            <ImageZoom
            image={{
              src: '/assets/images/per1.png',
              className: 'img',
              style: { width: '100%' }
            }}
            zoomImage={{
              src: '/assets/images/per1.png',
            }}
          />

          </div>
          <div>
            <h5>Cathy Smith, anti-vaccination advocate</h5>
            <ImageZoom
            image={{
              src: '/assets/images/per2.png',
              className: 'img',
              style: { width: '100%' }
            }}
            zoomImage={{
              src: '/assets/images/per2.png',
            }}
          />
          </div>
        </div>

        <br/>
        </div>
        <div id="ideation" className={ProjectStyles.basic_margin}>
          <div className={ProjectStyles.title_flex}>
            <h1>Ideation</h1>
          </div>

          <div>There were a few great pain point we had discovered during our research as well as bright spots.
            <li>Searching on the web could lead to potential misinformation</li>
            <li>Said misinformation can be spread easily especially if people do not know how to validate the data</li>
            <li>The creation of safe spaces (Strict facebook groups) cause an echo chamber that keeps people misinformed</li>

          <p>One bright is that users were looking for information. Users actively wished to be informed. We decided to focus on the web providing misinformation as the main painpoint becasue users were already searching and looking for data. 
          With this in mind, we came up with a few solutions intended to provide users with the chance to make a better choice.</p>
          
          <h5>Vetting Information</h5>
            <p>To vet the articles, we proposed a validating system that would rate the source, the authors and any entities behind it.
              In our initial research, we saw that data tied to emotionally charged events were being used to envoke fear even when the data was incorrect. 
            </p>

          <h5>Vetting Vaccines</h5>
            <p>To vet vaccines, we wanted to address the main concern people had, which was the ingredients. Our proposed solution was so show the ingredients but also show other common products that had these ingredients and why they were in the vaccine.</p>
          
          <p>Our goal was to give people a safe and reliable way to get correct information in a way that made sense to them.</p>
          </div>
        <div>
          </div>
          </div>

          <div id="lofi" className={ProjectStyles.basic_margin}>

        <div className={ProjectStyles.title_flex}>
          <h5>lo-fi prototype & user tests</h5>
          <h1>Wireframes</h1>
        </div>

        <img src="/assets/images/wireframes.png" width="100%" styles="margin: 0 auto;" alt="wireframes"/>
          <div className={ProjectStyles.title_flex}>
            <h1>Usability Testing</h1>
          </div>
          <p> I performed usability tests with the wireframes (n=8). The biggest problem I found was that people did not know how to compare the vaccines. They could not find the elements for it.</p>
          <div className={ProjectStyles.flex}>
            <ImageZoom
            image={{
              src: '/assets/images/p1oldsmall.png',
              
              className: 'img',
              style: { width: '50%' }
            }}
            zoomImage={{
              src: '/assets/images/p1old.png',
                          }}
          />
            <ImageZoom
            image={{
              src: '/assets/images/p1newsmall.png',
              
              className: 'img',
              style: { width: '50%' }
            }}
            zoomImage={{
              src: '/assets/images/p1new.png',
                          }}
          />
        </div>


          <p> The next page was the comparison page:</p>

          <div className={ProjectStyles.flex}>
            <ImageZoom
            image={{
              src: '/assets/images/p2oldsmall.png',
              
              className: 'img',
              style: { width: '50%' }
            }}
            zoomImage={{
              src: '/assets/images/p2old.png',
                          }}
          />
            <ImageZoom
            image={{
              src: '/assets/images/p2newsmall.png',
              
              className: 'img',
              style: { width: '50%' }
            }}
            zoomImage={{
              src: '/assets/images/p2new.png',
                          }}
          />
        </div>
        </div>

        <div id="hifi" className={ProjectStyles.basic_margin}>


          <div className={ProjectStyles.title_flex}>
            <h1>HIfi Prototype</h1>
          </div>
          <p>At this point, we created the high fi prototypes by using all of our own low-fi wireframes. I used illustrator and Invision to create this</p>
          <ImageZoom
          image={{
            src: '/assets/images/hifi.png',
            
            className: 'img',
            style: { width: '100%' }
          }}
          zoomImage={{
            src: '/assets/images/hifi.png',
                      }}
        />

          <div className={ProjectStyles.title_flex}>
            <h1>User Testing</h1>
          </div>

          <p>We found that language was very important for users. If scientific words were used, they had to be explained so it was easier to understand what was happening. As we iterated through the designs, we ended up making a lot of assumptions that turned out to be harmful. The biggest issue being the language. Users were very confused by words like “source validator” and “vaccine recommender”. When asked to find credibility of articles, they looked more towards general information because it was language they understood. When asked to search for vaccines, there was no links to a place to mark so they gravitated towards vaccine recommender.</p>

          <p>We had assumed that users may have been more knowledgeable than they were. Many didn’t really know much about vaccines. From observations, individuals in vaccine education and opinion groups generally demonstrated a greater awareness and affinity for vaccine-related terminology and research.
          </p>
</div>

        <div id="reflect" className={ProjectStyles.basic_margin}>

          <div className={ProjectStyles.title_flex}>
            <h1>Future Work & improvements</h1>
          </div>
          <p>Looking back at this project after a year. I believe we could have made a bigger effort to get to the heart of the problem. People were afraid of vaccines and making emotional choices. You can't fight emotional choices with rational data. Instead I think a better solution would be to offer parents a personal nurse who would be on call after the vaccine is administered just in case.</p>
          </div>

        <Nav items={['overview', 'research', 'interpretation', 'ideation', 'lofi', 'hifi', 'reflect']}>
          <li><a href="#overview">Overview</a></li>
          <li><a href="#research">Research</a></li>
          <li><a href="#interpretation">Interpretation</a></li>
          <li><a href="#ideation">Ideation</a></li>
          <li><a href="#lofi">LoFi Wireframes</a></li>
          <li><a href="#hifi">HiFi Prototype</a></li>
          <li><a href="#reflect">Reflection</a></li>
        </Nav>
        <Progress/>

      </Projects>
    );
  }
}

export default Vaxx;
