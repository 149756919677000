import React, { Component } from 'react';
import WebDesignStyles from './WebDesign.module.css';
import ImageZoom from 'react-medium-image-zoom';
import ProjectStyles from './projects/Projects.module.css'


class WebDesign extends Component {
  constructor(props){
    super(props)
    this.state = {
      filters:[]
    }
  }


  render() {
    return (
      <div className={WebDesignStyles.project_container}>
        <div className={ProjectStyles.title_flex}>
          <h1>Web Design</h1>
          <p>Web design concepts and ideas</p>
        </div>
        <ImageZoom
          image={{
            src: '/assets/images/layer.png',
            className: 'img',
            style: { width: '50%' }
          }}
          zoomImage={{
            src: '/assets/images/layer.png',
          }}
        />
        
      </div>
    );
  }
}

export default WebDesign;
