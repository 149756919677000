import React, { Component } from 'react';
import UndrawStyles from './Undraw.module.css';
import Projects from './Projects';
import ProjectInfo from './ProjectInfo';
import ProjectStyles from './Projects.module.css';


export default class Undraw extends Component {
  render() {
    return (
      <Projects
        img="/assets/images/undraw.webp"
        size={'58%'}
        title="Dribbble contest"
        tools={['xd', 'ai', 'pen']}
        roleList={['UI Designer']}

        color="#FF994B"
        timeline="2 Days"
        subtitle="This project was an entry for a Adobe XD contest hosted on Dribbble. I won first place">
      <ProjectInfo
          title="unDraw & Adobe XD Contest"
          roles="UI & UX Design"
          timeline="2 Days"
          description="This app helps user take care of their plants by showing them exactly what each plant is lacking. They will also have new plants recommended to them."
          problem="The prompt was to use Adobe XD and unDraw illustrations to create some kind of prototype."
          outcome="I won first place"
       />

       <div className={ProjectStyles.basic_margin}>
      
        <div className={UndrawStyles.btn_cont}><a href="https://dribbble.com/shots/6764060" target="_blank" rel="noopener noreferrer" className={UndrawStyles.button}>Check out the contest</a></div>

      <video width="100%" height="auto" autoPlay loop>
        <source src="/assets/undraw.mp4" type="video/mp4"/>
      </video>
      </div>
    </Projects>
    );
  }
}

