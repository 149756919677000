import React, { Component } from 'react';
import IllustrationsStyles from './Illustrations.module.css';
import ProjectThumbnail from './ProjectThumbnail';

const PROJECTS = [
{
    tags: ['ui', 'ux'],
    component: (
      <ProjectThumbnail
        location="/36daysoftype"
        title="#36daysoftype"
        roles="Graphic Design"
        size="40%"
        tools={['ai', 'pen', 'fig']}
        bgcolor="#dbe4f0"
        image="/assets/images/36daysoftype/36.png"
      />
    )
  },{
    tags: ['ui', 'ux'],
    component: (
      <ProjectThumbnail
        location="/misc"
        title="Misc Design Work"
        roles="Graphic Design"
        size="50%"
        bgcolor="#202c4a"
        tools={['xd', 'ai', 'fig']}

        image="/assets/images/illus.png"
      />
    )
  }, 
]
class Illustrations extends Component {
  constructor(props){
    super(props)
    this.state = {
      filters:[]
    }
  }

  renderProjects = () => {
    let comp = [];
    for (let i = 0; i < PROJECTS.length; i++) {
      let project = PROJECTS[i];
      if (this.state.filters.every(v => project.tags.includes(v))) {
        comp.push(PROJECTS[i].component);
      }
    }
    if (comp.length === 0) {
      comp.push(
        <div>
          Looks like I haven't done this combination yet. But I will one day!
        </div>
      )
    }
    return comp
  }


  render() {
    return (
      <div className={IllustrationsStyles.project_overview}>
      <div className={IllustrationsStyles.project_container}>
        {this.renderProjects()}
      </div>
</div>
    );
  }
}

export default Illustrations;
