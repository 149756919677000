import React, { Component } from 'react';
import classnames from 'classnames';
import PotluckStyles from './Potluck.module.css';
import Projects from './Projects';
import ProjectInfo from './ProjectInfo';
import ProjectStyles from './Projects.module.css';

import Nav from "../Nav";
import Progress from "./Progress";

import ImageZoom from "react-medium-image-zoom";
import UtilitiesStyles from "../utilities.module.css";
import Title from "./Title";


export default class GMenu extends Component {
  render() {
    return (
      <Projects
        img="/assets/images/potluck.png"
        size={'100%'}
        title="Potluck"
        color="#6C69EA"
        timeline="3 Months"
        tools={['vue', 'fig', 'pen']}
        roleList={['UX Design Intern']}
        people={<p>Me, Pantufla Cuántica (designer), <br />Eric Smith (Engineer + Manager)</p>}
        subtitle="A project management tool that aims to simplify project management">
      <ProjectInfo
          description={<p>In the Summer of 2020, I had a UX design internship with Saltwater to work on a project management tool called Potluck. I had a blast working with one other designer and one engineer and learned a lot about product strategy, developer hand off, design systems and design precision. You can checkout Potluck <a href="https://potluck.io/" target="_blank" rel="noopener noreferrer">here</a>.</p>}
          problem="After a recently UI update, the Monthly Active User (MAU) count had decreased by 30%, the sign up rate was sitting at 20% and there was a 85% drop off after the first interaction. My task was to figure out why."
          outcome="I improved the successful user test tasks rate of the application from 40% to 96% and was offered to stay on as a Product Designer."
       />

       
       <div className={PotluckStyles.project}>

          <div className={ProjectStyles.basic_margin} id="overview">
            <video autoplay="autoplay" width="100%" className={PotluckStyles.overview_vid}>
              <source src="/assets/images/hero.mp4" type="video/mp4"/>
                  Your browser does not support the video tag.
            </video>
          </div>
          <div className={PotluckStyles.grey} id="acquainted">
            <div className={ProjectStyles.basic_margin}>
              <Title
                title="Getting Acquainted"
                subtitle="Exploring Potluck" />
              <div className={classnames(UtilitiesStyles.d_flex, PotluckStyles.sec_research)}>
                <p>I started this project by learning about Potluck. I had meetings with another designer on the team as well as the CTO to learn the history and design decisions. Then I explored Potluck myself and watched FullStory sessions to conduct a heuristic analysis. These steps helped me map out the existing user flow.</p>
              </div>
            </div>
          <div>
              <div className={UtilitiesStyles.text_center}>
              <ImageZoom
                image={{
                  src: "/assets/images/pcurrent.png",
                  className: "img",
                  style: { width: "80%", margin: "20px auto" }
                }}
                zoomImage={{
                  src: "/assets/images/pcurrent.png"
                }}
              />
              </div>
          </div>
       </div>
       </div>


        <div className={ProjectStyles.grey} id="quant">
          <div className={ProjectStyles.basic_margin}>
            <Title
              title="Quantitative Data"
              subtitle="Checking Google Analytics" />
            <div>
              <p>In order to understand how effective the current onboarding is, I looked at both the Google Analytics and Fullstory sessions to see if there were any dropoffs and where they occurred.</p>

              <div
                className={classnames(
                  UtilitiesStyles.d_flex,
                  UtilitiesStyles.text_center,
                  PotluckStyles.sur_stats,
                )}
              >
                <div
                  className={classnames(
                    UtilitiesStyles.flex_one,
                    PotluckStyles.flex_one,
                  )}
                >
                  <h3 className={PotluckStyles.stat}>85%</h3>
                  <p className={PotluckStyles.research} width="200px">
                    Of visitors dropped off after the first interaction 
              </p>
                </div>
                <div
                  className={classnames(
                    UtilitiesStyles.flex_one,
                    PotluckStyles.flex_one
                  )}
                >
                  <h3 className={PotluckStyles.stat}>63%</h3>
                  <p className={PotluckStyles.research} width="200px">
                    Of visitors dropped off after the 2nd page
              </p>
                </div>

                <div
                  className={classnames(
                    UtilitiesStyles.flex_one,
                    PotluckStyles.flex_one
                  )}
                >
                  <h3 className={PotluckStyles.stat}>50%</h3>
                  <p className={PotluckStyles.research} width="200px">
                    Bounce rate for Potluck
              </p>
                </div>
              </div>
            
            <div style={{marginTop: 100}}>

            <Title
              title="The Monthly Active User Count"
              subtitle="Exploring Metrics" />
              <p>There was a 30% drop in monthly active users in the span of a week after a big change in how the application is used and displayed.</p>
              <ImageZoom
                image={{
                  src: "/assets/images/mau-old.jpg",
                  className: "img",
                  style: { width: "100%", margin: "20px auto" }
                }}
                zoomImage={{
                  src: "/assets/images/mau-old.jpg"
                }}
              />

            </div>
            </div>
          </div>
        </div>
        
        <div id="cur">
        <div className={PotluckStyles.grey}>
          <div className={ProjectStyles.basic_margin}>
            <Title
              title="Evaluation Research"
              subtitle="Getting People to test Potluck" />
            <div>
              <p>After seeing these metrics, I decided to conduct evaluation research with 5 individuals to assess what the issues with the product were. I recruited 1 tech lead for a bigger company, 1 startup CEO, 1 project manager and 2 individuals who have work experience and have used some sort of project management (PM) tool. Given the issues, I decided to test by watching them explore Potluck first. Then nudging them to find things. Here are some questions I had asked.</p>
              
              <div className={PotluckStyles.eval_research}>
              <li>What does this product do?</li>
              <li>What is a template?</li>
              <li>What is your daily project routine?</li>
              <li>Can you add a task?</li>
              <li>Can you add a due date?</li>
              <li>Can you assign a task to yourself?</li>
              <li>Does the landing page represent your experience?</li>
              <li>What is the product used for?</li>

              </div>
              

            </div>
          </div>
        </div>

        <div className={PotluckStyles.old_user_test}>
          <div className={ProjectStyles.basic_margin}>
            <Title
              title="User Test"
              subtitle="Quantifying problems" />

              <div
                className={classnames(ProjectStyles.table, PotluckStyles.table)}
              >
                <table>
                  <tbody>
                    <tr>
                      <th></th>
                      <th>User#1</th>
                      <th>User#2</th>
                      <th>User#3</th>
                      <th>User#4</th>
                      <th>User#5</th>
                    </tr>
                    <tr>
                    <td>Add new task</td>
                      <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.red}>&times;</td>
                    </tr>
                    <tr>
                      <td>
                      Assign yourself task
                  </td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                    </tr>
                    <tr>
                      <td>Open sidebar?</td>
                      <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.red}>&times;</td>
                    </tr>
                    <tr>
                      <td>Add subtasks</td>
                      <td className={PotluckStyles.green}>✓</td>
                    <td className={PotluckStyles.red}>&times;</td>
                    <td className={PotluckStyles.red}>&times;</td>
                    <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.red}>&times;</td>
                    </tr>
                    <tr>
                      <td>Clear what to do?</td>
                      <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.red}>&times;</td>
                    <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.red}>&times;</td>
                    </tr>
                    <tr>
                      <td>
                      Understood templates?
                  </td>
                    <td className={PotluckStyles.red}>&times;</td>
                    <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.green}>✓</td>
                    <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.green}>✓</td>
                    </tr>
                    <tr>
                      <td>
                      Add due date
                  </td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
        <div className={PotluckStyles.s}>
          <div className={ProjectStyles.basic_margin}>
            <Title
              title="The Challenges"
              subtitle="Insights Uncovered" />
            <div>

              <div className={classnames(UtilitiesStyles.d_flex, PotluckStyles.sec_research, PotluckStyles.insights)}>
                <div>
                  <img src="/assets/images/pdiscover.svg" alt=""/>
                  <br />
                  <h4>Discoverability</h4>
                  <p>A few users had a hard time finding features. The project interface provided no tutorials or examples for how to get started. There were also usability issues that led to people not know certain features existed. 
                  </p>
                </div>
                <div>
                  <img src="/assets/images/pprogress.svg" alt=""/>
                  <br />
                  <h4>How to show progress</h4>
                  <p>A major issue expressed by every tester was the lack of collaboration and progress. For managers and leaders, it was important to see how close the team was to completion and any blockers between issues. 
                  </p>
                </div>
                <div>
                  <img src="/assets/images/pvalue.svg" alt=""/>
                  <br />
                  <h4>How to show value</h4>
                  <p>Users expressed the landing page didn't accurately present what the product did and didn't find the value in it. They didn't know why or if they'd switch from their current project management tool.
                  </p>
                </div>
              </div>


            </div>
          </div>
        </div>


        <div className={PotluckStyles.challenge} id="chal">
          <div className={ProjectStyles.basic_margin}>
          <h3>The challenge</h3>
          <h1> How can we make features more discoverable, impart a sense of value, collaboration & project progression?</h1>
          </div>
        </div>
        <div className={PotluckStyles.g} id="idea">
          <div className={ProjectStyles.basic_margin}>
            <Title
              title="Ideation"
              subtitle="Exploring possibilities" />
                        I found many issues while doing research for this project but I narrowed it down into the main issues that would solve the problem. By boiling down my research into these goals, it helps me make sure my later design decision are grounded in my user research and actually solve the problem.
                <ImageZoom
              image={{
                src: "/assets/images/pideation.png",
                className: "img",
                style: { width: "100%", margin: "20px auto" }
              }}
              zoomImage={{
                src: "/assets/images/pideation.png"
              }}
            />
          </div>
        </div>

        <div id="itera">
        <div className={PotluckStyles.grey}>
          <div className={ProjectStyles.basic_margin}>
            <Title
              subtitle="Issue 1: Showing Value"
              title="Reducing Steps" />
              <p>Since there was a 63% dropoff after the 2nd interaction, I wanted to drive value as soon as possible. I reduced the onboarding flow from 5 steps down to 3 steps.</p>
          </div>
            

          <div className={UtilitiesStyles.text_center}>

            <ImageZoom
              image={{
                src: "/assets/images/pflownew.png",
                className: "img",
                style: { width: "70%", margin: "20px auto" }
              }}
              zoomImage={{
                src: "/assets/images/pflownew.png"
              }}
            />
          </div>
          

          <div className={ProjectStyles.basic_margin}>
          <Title
            title="Iteration & Collaboration"
            subtitle="Working with others" />
            <p>I started with low-fi prototypes and tested them quickly with users to hash out how I thought the flow should work. Once I had worked out the high level overview, another designer on the team took over the details of the design. We iterated over the details together and she designed them. The following are the low to mid level design iterations I went through personally.</p>

          <div className={UtilitiesStyles.text_center}>
          <ImageZoom
            image={{
              src: "/assets/images/piterations.png",
              className: "img",
              style: { width: "100%", margin: "20px auto" }
            }}
            zoomImage={{
              src: "/assets/images/piterations.png"
            }}
          />
            </div>

          </div>

        </div>

        <div className={ProjectStyles.basic_margin}>
          <Title
            title="Actively Working + Status"
            subtitle="Issue 2: How to show progress" />
          <div className={classnames(UtilitiesStyles.d_flex, PotluckStyles.actively_working)}>
            <div>
              <ImageZoom
                image={{
                  src: "/assets/images/pact1.png",
                  className: "img",
                  style: { width: "100%", margin: "20px auto" }
                }}
                zoomImage={{
                  src: "/assets/images/pact1.png"
                }}
              />
              <br />
              <h4>Low-Fidelity</h4>
              <p>We came up with many ideas. We'd pitch them and let the others critique them. I guerilla tested the best ideas for usability.</p>
            </div>
            <div>
              <ImageZoom
                image={{
                  src: "/assets/images/pact12.png",
                  className: "img",
                  style: { width: "100%", margin: "20px auto" }
                }}
                zoomImage={{
                  src: "/assets/images/pact12.png"
                }}
              />
              <br />
              <h4>High-Fidelity</h4>
              <p>We ended up picking the status setting on the task check. It was the simplest solution that performed the best in guerilla tests. 
                  </p>
            </div>
          </div>
        </div>

        <div className={PotluckStyles.grey}>
        <div className={ProjectStyles.basic_margin}>
          <Title
            title="Previewing Templates"
            subtitle="Issue 1: Showing Value" />
            <p>Templates were harder for people to understand partially because they didn't know what the product did and because they couldn't see previews. These are the following iterations we tried. In the end, we chose the 3rd option because it was the clearest and only available when the user looked for it. It also performed better in user tests.   </p>
          <div className={classnames(UtilitiesStyles.d_flex, PotluckStyles.template_box)}>
            <div>
              <ImageZoom
                image={{
                  src: "/assets/images/temp1.jpg",
                  className: "img",
                  style: { width: "100%", margin: "20px auto" }
                }}
                zoomImage={{
                  src: "/assets/images/temp1.jpg"
                }}
              />
              <br />
              <h4>1. Popup preview</h4>
                <p>Shows up before project is fully created. Shows preview but it's not as important</p>
            </div>
            <div>
              <ImageZoom
                image={{
                  src: "/assets/images/temp4.jpg",
                  className: "img",
                  style: { width: "100%", margin: "20px auto" }
                }}
                zoomImage={{
                  src: "/assets/images/temp4.jpg"
                }}
              />
              <br />
              <h4>2. Hover Preview</h4>
                <p>Shows up before project is fully created. Shows preview but it's not as important. The preview feels like a temporary experience. Seeing previews over takes the screen. 
                  </p>
            </div>
            <div>
              <ImageZoom
                image={{
                  src: "/assets/images/temp3.jpg",
                  className: "img",
                  style: { width: "100%", margin: "20px auto" }
                }}
                zoomImage={{
                  src: "/assets/images/temp3.jpg"
                }}
              />
              <br />
              <h4>3.  Popup modal + Dedicated previews</h4>
              <p>Popup after the project is created with the preview taking more attention. 
                  </p>
            </div>
          </div>

            <Title
              title="High Fidelity"/>
            <p>These were created in Figma and followed the Potluck branding styles, which included a 8px spacing guide.</p>
            <div className={classnames(UtilitiesStyles.d_flex, PotluckStyles.temp_hi)}>
              <div>
                <ImageZoom
                  image={{
                    src: "/assets/images/temphi1.jpg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/images/temphi1.jpg"
                  }}
                />
                <br />
                <h4>Light Mode</h4>

              </div>
              <div>
                <ImageZoom
                  image={{
                    src: "/assets/images/temphi2.jpg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/images/temphi2.jpg"
                  }}
                />
                <br />
                <h4>Dark Mode</h4>
              </div>
            </div>
        </div>
        </div>

        <div className={classnames(UtilitiesStyles.d_flex, PotluckStyles.ample_prjct)}>
          <div className={ProjectStyles.basic_margin}>
            <Title
              title="Sample Project"
              subtitle="Issue 3: Discoverability" />
            <p>One of the complaints we got was that users didn't know what else Potluck could offer. I added a sample project that shows tasks and gives users a way see what Potluck could do. We ran into a issue with how to show templates and let users create a project. I conducted a few guerilla tests along the way to find better solutions. </p>
            <div className={classnames(UtilitiesStyles.d_flex, PotluckStyles.template_box)}>
              <div>
                <ImageZoom
                  image={{
                    src: "/assets/images/proj3.jpg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/images/proj3.jpg"
                  }}
                />
                <br />
                <h4>1. Buttons on top</h4>
                <p>I tried having the button on the top but users would skip the tutorial and click the buttons. We knew it would have to come after they see the sample project.</p>
              </div>
              <div>
                <ImageZoom
                  image={{
                    src: "/assets/images/proj1.jpg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/images/proj1.jpg"
                  }}
                />
                <br />
                <h4>2. One button at the bottom</h4>
                <p>I tried having one button for templates but it that meant people would have to delete all tasks if they just wanted to create a project. 
                  </p>
              </div>
              <div>
                <ImageZoom
                  image={{
                    src: "/assets/images/proj4.jpg",
                    className: "img",
                    style: { width: "100%", margin: "20px auto" }
                  }}
                  zoomImage={{
                    src: "/assets/images/proj4.jpg"
                  }}
                />
                <br />
                <h4>3. Buttons at the bottom</h4>
                <p>This was the best of both worlds. It follows a logical narrative and offers different options for how to build a project. We ended up going this this version. 
                  </p>
              </div>
            </div>

            <Title
              title="High Fidelity" />
              <div>
                <ImageZoom
                  image={{
                    src: "/assets/images/projhifi.jpg",
                    className: "img",
                    style: { 
                      width: "100%", 
                      margin: "20px auto",
                      borderRadius: '10px',
                      boxShadow: '4px 10px 90px #44556644'
                    }
                  }}
                  zoomImage={{
                    src: "/assets/images/projhifi.jpg"
                  }}
                />
              </div>
          </div>
          </div>
        </div>
        <div className={classnames(PotluckStyles.grey, PotluckStyles.landing)} id="landing">
        <div className={classnames(UtilitiesStyles.d_flex, PotluckStyles.landing_page)}>
          <div className={PotluckStyles.landing_text}>
            <span className={PotluckStyles.landing_text_inner}>
            <Title
              title="Landing Page"
              subtitle="Issue 1: Showing Value" />
            <p>With feedback I got from testing the existing site, I redesigned the landing page by making it more company focused, adding social proof. Pantufla created the amazing animation to make it more visually interesting. </p>
            </span>
          </div>
          <div className={PotluckStyles.image}></div>
        </div>
        </div>

        <div className={PotluckStyles.s} id="finaltests">
          <div className={ProjectStyles.basic_margin}>
            <Title
              title="Final Tests"
              subtitle="Usability Test" />
            <div>
              <p>Even though each component has been tested for usability, I wanted to make sure the full onboarding experience worked and made sense. I conducted a full usability test with 7 people. 3 of them had done the test before (to see if the improvements helped them). Here are the results. </p>
                  <div
                    className={classnames(ProjectStyles.table, PotluckStyles.table)}
                  >
                    <table>
                      <tbody>
                        <tr>
                          <th></th>
                          <th>User#1</th>
                          <th>User#2</th>
                          <th>User#3</th>
                          <th>User#4</th>
                          <th>User#5</th>
                          <th>User#6</th>
                          <th>User#7</th>
                        </tr>
                        <tr>
                          <td>Previously Tested?</td>
                          <td>Yes</td>
                          <td>Yes</td>
                          <td>Yes</td>
                          <td>No</td>
                          <td>No</td>
                          <td>No</td>
                          <td>No</td>
                        </tr>
                        <tr>
                          <td>Add new task</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                        </tr>
                        <tr>
                          <td>
                        Assign yourself task
                  </td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                        </tr>
                        <tr>
                          <td>Open sidebar?</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>

                        </tr>
                        <tr>
                          <td>Add subtasks</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.red}>&times;</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>

                        </tr>
                        <tr>
                      <td>Clear what to do?</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>
                      <td className={PotluckStyles.green}>✓</td>

                        </tr>
                        <tr>
                          <td>
                        Understood templates?
                  </td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.red}>&times;</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                        </tr>
                        <tr>
                          <td>
                            Add due date
                  </td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                          <td className={PotluckStyles.green}>✓</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              
            <p style={{ marginTop: 70 }} className={classnames(PotluckStyles.final_end)}>
              <div className={classnames(PotluckStyles.final_stats)}>
                <h2>Percent of tasks that were successfully completed</h2>

                <div className={classnames(UtilitiesStyles.d_flex, PotluckStyles.final_stats_inner)}>
                  <div
                    className={classnames(
                      UtilitiesStyles.flex_one,
                      PotluckStyles.final_stat_number,
                      PotluckStyles.final_stat_num,
                    )}
                  >
                    <h4>Before</h4>
                    <h3 className={PotluckStyles.stat}>40%</h3>
                  </div>
                  <div
                    className={classnames(
                      UtilitiesStyles.flex_one,
                      PotluckStyles.final_stat_number,
                    )}
                  >
                    <h4>After</h4>
                    <h3 className={PotluckStyles.stat}>96%</h3>
                  </div>
                </div>

                </div>
                

                
                
              I am so happy with the results. The users that read the sample project were able to understand how to use Potluck and saw the value. Users who didn't read the sample project were still able to figure it out on their own. There were significant improvements on all tasks. Some went from 0% success to 100%.</p>
              <div className={classnames(PotluckStyles.final_results)}>
              <ImageZoom
                image={{
                  src: "/assets/images/poimprove.jpg",
                  className: "img",
                  style: {
                    width: "100%",
                    margin: "20px auto",
                  }
                }}
                zoomImage={{
                  src: "/assets/images/poimprove.jpg"
                }}
              />

            </div>
          </div>
        </div>

        <div id="reflect" className={PotluckStyles.reflect}>
          <div className={ProjectStyles.basic_margin}>

            <Title
              title="Next Steps"
              subtitle="How to go further" />
            <div className={UtilitiesStyles.d_flex}>
              <p className={classnames(UtilitiesStyles.flex_1, PotluckStyles.reflect_text)}>
                <b>Tracking Metrics</b> <br />
                  The next step is to track changes in our dropoff rates, bounce rates and daily active user counts.
            </p>

              <p className={classnames(UtilitiesStyles.flex_1, PotluckStyles.reflect_text)}>
                <b>Sorting Tasks</b>
                <br />I would like to add sorting into the product. We didn't get a chance to include task sorting in this project but it was something users mentioned. They wanted to be able to sort by task status, due dates, assignee etc.
              </p>
            </div>


            <Title
              title="Lessons Learned"
              subtitle="How to be better" />
            <div className={UtilitiesStyles.d_flex}>
              <p className={classnames(UtilitiesStyles.flex_1, PotluckStyles.reflect_text)}>
                <b>Projects get messy</b><br />
                 Lots of changes can make a project very messy. They can also impact the measurement of final metrics. Each change should be paired with a metric we intend to improve. It's important to organize files for dev hand off. They should have easy access to assets and know exactly what changes they have to make. Follow (or make) the design system.
              </p>

              <p className={classnames(UtilitiesStyles.flex_1, PotluckStyles.reflect_text)}>
                <b>Be precise in your designs</b>
                <br />
                Be wary of padding and margins. Developers won't know what values to set if you don't. Take the guess work out of it and make your designs as exact as possible. Don't make your devs designers unless they want it.
              </p>
            </div>
          </div>
        </div>
        <Nav
          items={[
            "overview",
            "acquainted",
            "quant",
            "cur",
            "chal",
            "idea",
            "itera",
            "landing",
            "finaltests",
 
            "reflect"
          ]}
        >
          <li>
            <a href="#overview">Overview</a>
          </li>
          <li>
            <a href="#acquainted">Getting Acquainted</a>
          </li>
          <li>
            <a href="#quant">Quantitative Data</a>
          </li>
          <li>
            <a href="#cur">Current Usability</a>
          </li>
          <li>
            <a href="#chal">The Challenge</a>
          </li>
          <li>
            <a href="#idea">Ideation</a>
          </li>
          <li>
            <a href="#itera">Iterations</a>
          </li>
          <li>
            <a href="#landing">Landing Page</a>
          </li>
          <li>
            <a href="#finaltests">Final Tests</a>
          </li>
          <li>
            <a href="#reflect">Reflection</a>
          </li>
        </Nav>
        <Progress />
    </Projects>
    );
  }
}

