import React, { Component } from 'react';
import WaldenStyles from './Walden.module.css';
import Projects from './Projects';
import ProjectStyles from './Projects.module.css';
import ProjectInfo from './ProjectInfo';
import Nav from '../Nav';
import Progress from './Progress';

class Walden extends Component {
  render() {
    return (
      <Projects
        img="/assets/images/waldennew.webp"
        size="100%"
        timeline="3 Months"
        color="#58AEED"
        tools={['ai', 'pen', 'code']}
        roleList={['Front-End engineer', 'UX Researcher', 'UX Designer']}
        title="Walden International School"
        subtitle="Walden is a private school that was unable to retain users on their site. I <b>redesigned and implemented the site</b> with user research to guide the process."
      >
        <ProjectInfo
          title="Walden International School"
          timeline="3 Months"
          problem="Parents were having a hard time navigating the website and trying to find the information they needed."
          outcome="I re-designed and coded the website. The bounce rate dropped by 30% and session duration improved by 63%"
          description={(<p>Walden International School is a private school located in Brampton, Ontario. I made them a brand new site that helps parents learn more about the school. They saw through google analytics that users were <b>not staying on the site very long</b>. I wanted to improve the site to be  <b>useful for parents.</b></p>)}
       />
      <div>
        <div className={WaldenStyles.walden_container}>
          <div id="heu" className={ProjectStyles.basic_margin}>
    
          <div className={ProjectStyles.title_flex}>
            <h1>Heuristic Analysis</h1>
          </div>

          <p>I did a heuristic analysis on the existing website to find some basic problems I could find. </p>

              <img src="/assets/images/waldenheu.png" style={{ width: '100%' }} alt="walden heuristics"/>
          </div>
          <div id="research" className={ProjectStyles.basic_margin}>

          <div className={ProjectStyles.title_flex}>
            <h1>User Research</h1>
          </div>

          <p>The next step was to actually talk to parents. I did <b>guerrilla testing</b> with parents and some ad hoc user testing of the site. I had parents try and find information about the programs offered and the tuition amount.</p>
          <p>
                <img src="/assets/images/waldeneval.png" style={{ width: '100%' }} alt="old parsehub evaluation" />
          </p>
          </div>
            <div id="ideating" className={ProjectStyles.basic_margin}>

          <div className={ProjectStyles.title_flex}>
            <h1>ideating</h1>
          </div>
              <p>I found the biggest problem with the site to be navigation. I performed a card sorting analysis to place the navigation in the correct place. I also performed competitor analysis to see how other schools showed links and how </p>
              <img src="/assets/images/postits.png" style={{ width: '100%' }} alt="card sorting"/>
          </div>

            <div id="final" className={ProjectStyles.basic_margin}>

          <div className={ProjectStyles.title_flex}>
            <h1>The Final Site</h1>
          </div>
              <img src="/assets/images/waldenfinal.png" style={{ width: '100%' }} alt="final walden site"/>
              </div>

            <div id="result" className={ProjectStyles.basic_margin}>

          <h1>Results</h1>
            <p>I was then responsible for coding it in ReactJS. Once the site had been live for a year, I looked back at the analytics. The session duration had increased and bouce rate dropped by about 30% on average.</p>

            <div className={WaldenStyles.container}>
              <div className={WaldenStyles.flex_item}>
                <h5>Session Duration</h5>

                <img src="/assets/images/session.png" width="100%" alt="session duration"/>
                <p> Up 63% since last year</p>
              </div>
              <div className={WaldenStyles.flex_item}>
                <h5>Bounce Rate</h5>
                <img src="/assets/images/bouncerate.png" width="100%" alt="Bounce rate"/>
                <p>Bounce rate dropped about 30% on average</p>
              </div>
            </div>
            </div>

      </div>
      </div>
        <Nav items={['overview', 'heu', 'research', 'ideating', 'final', 'result']}>
          <li><a href="#overview">Overview</a></li>
          <li><a href="#heu">Heuristics</a></li>
          <li><a href="#research">Research</a></li>
          <li><a href="#ideating">Ideation</a></li>
          <li><a href="#final">Final</a></li>
          <li><a href="#result">Results</a></li>
        </Nav>
        <Progress/>
      </Projects>
    );
  }
}

export default Walden;
