import React from 'react';
import themedGooeyNav4 from '../theme/themedGooeyNav4.module.css';
import GooeyNavItem from '../components/gooeyNavItem';
import GooeySvg from '../components/gooeySvg';


export default class Nav extends React.Component {
    onToggle() {
        this.props.onStateChange({
            isOpen: true
        });
    }

    getNavItems(theme) {
        let delay = 0;
        return this.props.features.map(name => {
            delay = delay + 100
            return (
                <GooeyNavItem
                    key={name}
                    delay={delay}
                    theme={theme}
                    icon={name} />
            );
        });

    }

    componentWillMount() {
        if (document.querySelector("#gooey-nav-svgs")) return;

        const domNode = GooeySvg({ id: "gooey-nav-svgs" });
        document.body.appendChild(domNode);
    }

    render() {

        let themedGooeyNav = themedGooeyNav4;

        return (
            <nav className={themedGooeyNav.menu}>
                <label>
                </label>
                {this.getNavItems(themedGooeyNav)}

            </nav>
        );
    }
}
