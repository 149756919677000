import React, { Component } from 'react';
import MiscStyles from './Misc.module.css';
import ImageZoom from 'react-medium-image-zoom';
import ProjectStyles from './projects/Projects.module.css'


class Misc extends Component {
  constructor(props){
    super(props)
    this.state = {
      filters:[]
    }
  }


  render() {
    return (
      <div className={MiscStyles.project_container}>
      <div className={ProjectStyles.title_flex}>
      </div>
        <ImageZoom
        image={{
          src: '/assets/images/pears.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/pears.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/lemons.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/lemons.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/draw1.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/draw1.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/draw2.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/draw2.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/draw3.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/draw3.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/blogpic.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/blogpic.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/toronto.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/toronto.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/pur.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/pur.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/hello.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/hello.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/moment.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/moment.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/bahen.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/bahen.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/rano.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/ranobig.png',
        }}
      />
        <ImageZoom
        image={{
          src: '/assets/images/fireworks.png',
          className: 'img',
          style: { width: '50%' }
        }}
        zoomImage={{
          src: '/assets/images/fireworks.png',
        }}
      />
      </div>
    );
  }
}

export default Misc;
