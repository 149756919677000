import React from 'react';
import HomeStyles from './Home.module.css';
import ProjectThumbnail from './ProjectThumbnail';
import Fade from 'react-reveal/Fade';

const PROJECTS = [
  {
    tags: ['ux', 'fe', 'ui'],
    component: (
      <ProjectThumbnail
        key={7}
        location="/projects/walden"
        title="Walden"
        class={HomeStyles.walden}
        id="walden"
        bgcolor="#58AEED"
        size="80%"
        tools={['ai', 'pen', 'code']}
        desc="Walden is a private school that was unable to retain users on their site. I redesigned and implemented the site with user research to guide the process."
        roles="Frontend Development • UX Design"
        image="/assets/images/walden1.png"
      />
    )
  },
   {
    tags: ['ui'],
    component: (
      <ProjectThumbnail
        key={6}
        location="/projects/undraw"
        title="UnDraw X Adobe XD"
        roles="UI Design"
        class={HomeStyles.undraw}
        id="undraw"
        bgcolor="#FF994B"
        size="40%"
        tools={['xd', 'ai', 'pen']}

        desc="The prompt was to use Adobe XD and unDraw illustrations to create an interactive prototype. I ended up winning first place"
        image="/assets/images/undraw.png"
      />
    )
  },
  {
    tags: ['fe', 'gd'],
    component: (
      <ProjectThumbnail
        key={8}
        location="/projects/uofthacks"
        title="UofTHacks"
        id="uoft"
        class={HomeStyles.uoft}
        bgcolor="#FF4B5A"
        tools={['pen', 'ai', 'code']}

        desc="UofTHacks is a student run hackathon at the University of Toronto. I designed the branding, developed the website and lead developers"
        roles="Frontend Development • Graphic Design"
        image="/assets/images/uofthacks1.png"
      />
      )
    },
  {
    tags: ['fe', 'gd'],
    component: (
      <ProjectThumbnail
        key={9}
        location="/projects/vaxx"
        title="Vaxx"
        id="uoft"
        class={HomeStyles.uoft}
        bgcolor="rgb(20, 236, 206)"
        tools={['pen', 'inv', 'code']}
        desc="Vaxx is a tool to help people be more comfortable with vaccines. By familiarizing foreign ingredients with things that can be found near by."
        roles="Frontend Development • Graphic Design"
        image="/assets/images/prodmock.png"
      />
      )
    },

]

export default class Old extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      filters:[],
      textIdx: 0
    }
  }

  filter = (filter) => {
    let state = this.state.filters;
    if (state.includes(filter)) {
      var index = state.indexOf(filter);
      state.splice(index, 1);
    } else {
      state.push(filter);
    }
    this.setState({
      filters: state
    });
  }

  componentDidMount() {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 1500);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }


  renderProjects = (projects) => {
    let comp = [];
    for (let i = 0; i < projects.length; i++) {
      let project = projects[i];
      if (this.state.filters.every(v => project.tags.includes(v))) {
        comp.push(projects[i].component);
      }
    }
    return comp
  }


  render() { 

    return (
      <div className="Home">
        <div className={HomeStyles.old_content}>
          <h6>Blast from the past</h6>
          <h1>Older Projects</h1>

          <Fade bottom distance="50px">
          <div className={HomeStyles.project_container}>
            {this.renderProjects(PROJECTS)}
          </div> 
        </Fade>
        </div>
      </div>
    );
  }
}

