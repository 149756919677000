import React, { Component } from 'react';
import Projects from './Projects';
import ProjectInfo from './ProjectInfo';

export default class Wish extends Component {
  render() {
    return (
      <Projects
        img="/assets/images/wish.png"
        size={'80%'}
        roleList={['UX Designer']}
        title="Wish Case Study"
        color="#2FB7EA"
        tools={['maze', 'fig', 'pen']}
        slideDeck="https://docs.google.com/presentation/d/1vlGuvlSZWU_sVFhMgJbxdaHYiuTpMV310kyBwO83kok/edit?usp=sharing"
        timeline="4 Days"
        subtitle="A personal case study showcasing my process or trying to improving engagement on the e-commerce mobile app Wish.">
      <ProjectInfo
          title="Wish"
          timeline="4 Days"
          description="Wish is a e-commerce app aimed at make shopping fun. I worked on this case study as a way to improve the engagement of the app. I spent 4 days on this and had a blast trying to find a solution, iterating and testing."
          problem="How can we improve the Wish mobile app to have an impact on user acquisition, retention, engagement, sales or other attributes?
          "
          outcome={<span> 91% of user testers agreed that my solution was more engaging than regular scrolling. You can view the case study here: <a href="https://docs.google.com/presentation/d/1vlGuvlSZWU_sVFhMgJbxdaHYiuTpMV310kyBwO83kok/edit?usp=sharing" target="_blank" without rel="noopener noreferrer"> View Case Study</a>
          
          </span>}
       />
    </Projects>
    );
  }
}

