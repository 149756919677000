import React, { Component } from 'react';
import classnames from 'classnames';
import ParsehubStyles from './Parsehub.module.css';
import Projects from './Projects';
import ImageZoom from 'react-medium-image-zoom';
import ProjectStyles from './Projects.module.css';
import UtilitiesStyles from '../utilities.module.css';
import ProjectInfo from './ProjectInfo';

import FontAwesome from 'react-fontawesome';
import Nav from '../Nav';
import Progress from './Progress';

export default class Parsehub extends Component {
  render() {
    return (
      <Projects
        img="/assets/images/parsehub.webp"
        size="90%"
        title="Parsehub"
        timeline="3 Months"
        people={<p>Me, CEO, Developers, Product Supporters</p>}
        tools={['xd', 'ai', 'ang']}
        roleList={['Front End Engineer', 'UX Designer', 'Graphic Designer']}
        color="#FF9396"
        subtitle="Parsehub is a web scraping tool intended for those who cannot code. The interface is a point and click tool to create scraping projects.">
        <div id="overview">
        <ProjectInfo
          title="Parehub Redesign & Improvements"
          timeline="3 Months"
          problem="How might we help users collect large volumes of data in a structured way without writing a script?"
            outcome="User testing success rate increased by 166%, test complete time was reduced by 50% and support dependency for new sales decreased by 50%"
          description={(<p>The internet is full of data. Although some of it is useful, it can be hard and/or annoying trying to collect it all. It is easy to do it if you know how to code. Just write a script for the use case, but what if you can't code? <b>How might we help users collect large volumes of data in a structured way without writing a script?</b></p>)}
       />
       </div>
       <div id="research" className={ProjectStyles.basic_margin}>

          <div className={ProjectStyles.changes_title} style={{marginTop: 50}}>
            <h1>What are some use cases?</h1>
        </div>
          <p style={{marginTop: 20}}>
            <ol>
              <li>Collecting competitor prices to see if your own prices are comparable</li>
              <li>Realtors collecting rental information for a list of properties</li>
              <li>Lead generation for finding potential customers</li>
              <li>Social media sentiment analysis or reviews of products</li>
            </ol>
          </p>
          <div className={ProjectStyles.title_flex}>
            <h1>Problem Space</h1>
          </div>
          <p>When I joined parsehub, user tests were being done and the numbers were stagnating at 30% of users being able to complete the tasks fully. Users would get frustrated by the tool and quit. Often they were doing
            the wrong thing but assumed it was correct becasue their mental modal was different. After a while of getting to know the product, I sat down to explore the issues both qualitatively and quantitatively. 
          </p>

          <div className={ProjectStyles.title_flex}>
            <h1>Who are the existing users?</h1>
          </div>
          <div className={ParsehubStyles.demos}>
          <div>
            <FontAwesome
                name='birthday-cake'
                size='5x'
                style={{margin: '0 5px', color: '#0074ff' }}
              />
            <h1>50%</h1>
            <p>50% of the users are aged 25-34 years old with 35-44 being 2nd at 20%</p>
            </div>
            <div className={ParsehubStyles.gender}>
              <FontAwesome
                name='male'
                size='2x'
                style={{margin: '0 5px', color: '#0074ff' }}
              />
              <FontAwesome
                name='male'
                size='2x'
                style={{margin: '0 5px', color: '#0074ff' }}
              />
              <FontAwesome
                name='male'
                size='2x'
                style={{margin: '0 5px', color: '#0074ff' }}
              />
              <FontAwesome
                name='male'
                size='2x'
                style={{margin: '0 5px', color: '#0074ff' }}
              />
              
              <FontAwesome
                name='male'
                size='2x'
                style={{margin: '0 5px', color: '#0074ff' }}
              />
              <br/>
              <FontAwesome
                name='male'
                size='2x'
                style={{margin: '0 5px', color: '#0074ff' }}
              />
              
              <FontAwesome
                name='male'
                size='2x'
                style={{margin: '0 5px', color: '#0074ff' }}
              />
              <FontAwesome
                name='male'
                size='2x'
                style={{margin: '0 5px', color: '#0074ff' }}
              />
              <FontAwesome
                name='female'
                size='2x'
                style={{margin: '0 5px', color: '#0074ff44' }}
              />
              <FontAwesome
                name='female'
                size='2x'
                style={{margin: '0 5px', color: '#0074ff44' }}
              />
              <h1>77%</h1>
              <p>77% of Parsehub users are men</p>
            </div>
            <div>
            <FontAwesome
                name='search-dollar'
                size='5x'
                style={{margin: '0 5px', color: '#0074ff' }}
              />
            <h1>Marketers</h1>
            <p>Most users are marketers or individuals in the area of shopping and technology</p>
            </div>
          </div>

          <div className={classnames(UtilitiesStyles.d_flex, ProjectStyles.box_cont)}>
            <div className={classnames(UtilitiesStyles.flex_1, ProjectStyles.box)}>
                <div className={ProjectStyles.title_flex}>
                  <h5>qualitative research</h5>
                </div>
                  <FontAwesome
                    name='laptop-code'
                    size='5x'
                    style={{margin: '40px', color: '#0074ff' }}
                  />
                <p>Parsehub offers 30 min calls for support. I shadowed the supporters on the calls to understand what kinds of people were using Parsehub, what they've tried and what they want to do with Parsehub.</p>

              <img src="https://www.intercom.com/_next/static/images/intercom-logo-fd3138175b58ce4c7f6475cac3f5af5b.png" style={{ margin: '40px', color: '#0074ff' }} width="25%" alt="intercom"/>
                <p>The support team uses Intercom. I spent time reading about the types of questions people would ask and which companiees they were from.</p>
            </div>

            
            <div className={classnames(UtilitiesStyles.flex_1, ProjectStyles.box)}>
                <div className={ProjectStyles.title_flex}>
                  <h5>qualitative research</h5>
                </div>
                  <FontAwesome
                    name='heart-broken'
                    size='5x'
                    style={{ margin: '40px', color: '#0074ff' }}
                  />
                <p>Parsehub kept a record of broken projects and the reason. I went through this data to find the most common issues.</p>

              <FontAwesome
                name='chart-bar'
                size='5x'
                style={{ margin: '40px', color: '#0074ff' }}
              />
                <p>I spent some time looking into the <b>supporter influence metric</b>, which is the percent of people who are buying and using parsehub without any help from supporters. We were averaging 27%.</p>
                
            </div>
          </div>

          <div className={ProjectStyles.infos}>
            <div> <h1>39%</h1>
                <p>39% of broken projects came from the user unable to select items they wanted correctly. Everything on the page was being selected.</p></div>
            <div> <h1>29%</h1>     <p>29% of projects were abandoned becasue the user did not know how to click on an object in order to get to the element being clicked on.</p></div>
            <div> <h1>10%</h1><p>10% of problems came from people who didn't know how to get information from the next page (pagination).</p></div>
          </div>
          </div>

        <div id="goal" className={ProjectStyles.basic_margin}>
          <div className={ProjectStyles.title_flex}>
            <h1>The goal: Help people understand how to use Parsehub</h1>
          </div>

          <div className={classnames(UtilitiesStyles.d_flex, UtilitiesStyles.flex_center, ParsehubStyles.chall)}>
            <div className={ProjectStyles.flex_one}>
              <p>The biggest problem was that people didn't understand that in order to do something to an element, you had to first select it and then add commands to it. The word 'command' was nowhere on the application. Most users would just keep clicking things.</p>
            </div>
            <div className={ProjectStyles.flex_one}>
              <img src="/assets/images/phold.png" width="100%" alt="Old Parsehub"/>
              <h6 style={{textAlign: 'center'}}>The old parsehub</h6>
            </div>
          </div>
          </div>
       <div id="heu" className={ProjectStyles.basic_margin}>

          <div className={ProjectStyles.title_flex}>
            <h1>Heuristics evaluation</h1>
          </div>
          <p>For the first design sprint, I decided to focus on improving the clutter of the interface. I performed a heuristic evaluation to improve the UI and make it easier to understand. Focusing on just the sidebar of the application was key because that is where users paid most attention.</p>

          <div style={{margin: '70px auto'}}><img src="/assets/images/phfixes.png" width="100%" alt="parsehub ui issues" style={{borderRadius: 20}}/></div>
</div>
          <div id="ideating" className={ProjectStyles.basic_margin}>

            <div className={ProjectStyles.title_flex}>
              <h1>Ideating solutions</h1>
            </div>
            <p style={{ marginBottom: ' 50px' }}>Beyond the visual design, I wanted actually do more to help people understand parsehub. I set up meetings with developers and customer support representatives to help me brainstorm different ideas.
              The supporters mentioned that on calls and in Intercom, the average user will have done the <b>interactive tutorial</b>, but many of them don't understand it or even remember it. I decided to tackle the problem a little at a time and rescope it
              by improving tutorials and update the UI to a cleaner interface. I chose to do this becasue we already had existing customers that were familiar with how Parsehub worked. It is also lets us use the rest of the site as a control to really is our solutions were working. Here are the ideas we decided on.</p>

            <h1 style={{ marginBottom: ' 20px' }}>Using a mnemonic</h1>
            <p style={{ marginBottom: ' 50px' }}>A mnemonic is a memory device used to help people remember things. We wanted to introduce an alliteration based mnemonic into the tutorial.</p>
            <h5>"Select, Correct, Command"</h5>
            <p style={{ marginBottom: ' 50px' }}>First you must select the element, then make sure all the correct ones are added and then add any commands you need.</p>

            <h1 style={{ marginBottom: ' 20px' }}>Introducing the word Command</h1>

            <div className={classnames(UtilitiesStyles.d_flex, UtilitiesStyles.flex_center, ParsehubStyles.chall)}>
              <div style={{ marginRight: '50px' }}>
                <img src="/assets/images/commands.png" width="100%" style={{ borderRadius: '6px' }} alt="commands" />
                <h6 style={{ textAlign: 'center' }}>Parsehub Comands</h6>
              </div>
              <div>
                <p style={{ marginBottom: ' 60px' }}>Since 'click' and 'select' were commands, they need to be presented as such. So in every explanation of the tutorial, it must be iterated that these are commands that have to be applied to things that are selected.</p>
              </div>
            </div>

            <h1 style={{ marginBottom: ' 20px', marginTop: '50px' }}>Updating the tutorials to be more visually interesting</h1>

            <p>Currently the style of the tutorials is very plain, which makes it harder to remember when all the elements are too similar.</p>


          <div className={ProjectStyles.title_flex}>
            <h1>Iterations</h1>
          </div>
          <p><b>Unfortunately I cannot show this part of the process through images but I will describe them.</b></p>
          <p>I started off making sketches and user flows for how the tutorial should go. It was important to nail the length of the tutorial to ensure users didn't forget things. By working with customer support reps, I was able to use the ideas mentioned above to refine the tutorial.</p>
          <p>I then created multiple mid-fi prototypes in order to access information architecture and ensure that the interactive tutorial placement was correct. It was a quick and dirty of making sure the layout of parsehub was what people expected it and people knew how to progress through the tutorial.  </p>
          <p>The final prototype I coded it in HTML/CSS and Angular. In order to assess the intricate pathways and actual truth of the changes, I had to watch users try to scrape a website and this had to be done via a coded prototype. I had some help from developers who explained how the parsehub tutorial worked so I built off of their suggestions. </p>

 
            <img src="/assets/images/phnew.png" width="100%" style={{ borderRadius: '6px' }} alt="new"/>
        </div>

        <div id="final" className={ProjectStyles.basic_margin}>    
          <div className={ProjectStyles.title_flex}>
            <h1>Final Design</h1>
          </div>

          <ImageZoom
            image={{
              src: '/assets/images/phnews.png',
              className: 'img',
              style: { borderRadius: '6px', width:'100%'}
            }}
            zoomImage={{
              src: '/assets/images/phnews.png',
            }}
          />
        </div>
        <div id="utest" className={ProjectStyles.basic_margin}>
          <div className={ProjectStyles.title_flex}>
            <h1>User Testing</h1>
          </div>
          <p>The user tests were very promising. before the change, the user tests were averaging 30% completion of the test but after the new changes, <b>80% of the user testers were able to complete the tasks </b>, given that they did the tutorial. Through the tutorial some of the users were heard saying "Select, Correct, Command" to themselves as a way to remember what to do. The changes will be in production soon.</p>
          <p>After a month, the supporter influnce dropped to 14%. We also got feedback from users who posted about Parsehub on social media.</p>

          <ImageZoom
            image={{
              src: '/assets/images/phfeedback.png',
              className: 'img',
              style: { borderRadius: '6px', width:'100%'}
            }}
            zoomImage={{
              src: '/assets/images/phfeedback.png',
            }}
          />
          </div>

        <div id="reflect" className={ProjectStyles.basic_margin}>
          <div className={ProjectStyles.title_flex}>
            <h1>Lessons Learned</h1>
          </div>

          <p>I should <b>explore more before picking a solution </b>to make sure the design in the best it can be. Don't pick the first idea and run with it and figure out if it really is the best idea.</p>


          <div className={ProjectStyles.title_flex}>
            <h1>Future Work</h1>
          </div>

          <p>There is still a disconnect in how the user expects parsehub to work and how it currently works. People are scared to delete commands becasue there is no way to undo. There needs to be work to how select is done and what it means to 'click' an element.</p>
          </div>
        <Nav items={['overview', 'research', 'goal', 'heu', 'ideating', 'final', 'utest', 'reflect']}>
          <li><a href="#overview">Overview</a></li>
          <li><a href="#research">Research</a></li>
          <li><a href="#goal">Goal</a></li>
          <li><a href="#heu">Heuristics</a></li>
          <li><a href="#ideatin">Ideation</a></li>
          <li><a href="#final">Final</a></li>
          <li><a href="#utest">User Testing</a></li>
          <li><a href="#reflect">Reflection</a></li>
        </Nav>
        <Progress/>
    </Projects>





    );
  }
}

