import React, { Component } from 'react';
import NotFoundStyles from './NotFound.module.css';

class NotFound extends Component {
  render() {
    return (
      <div className={NotFoundStyles.container}>
        <img src="/assets/images/404.png" alt="404"/>
        <h1>Sorry! That page does not exist!</h1>
      </div>
    );
  }
}

export default NotFound;
