export default {
    'xd': 'https://upload.wikimedia.org/wikipedia/commons/c/c2/Adobe_XD_CC_icon.svg',
    'maze': '/assets/images/maze.jpg',
    'code': '/assets/images/react.svg',
    'ai': '/assets/images/ai.jpg',
    'inv': 'https://cdn.worldvectorlogo.com/logos/invision.svg',
    'fig': '/assets/images/figma.jpg',
    'ang': '/assets/images/angular.jpg',
    'pen': 'https://image.flaticon.com/icons/svg/1001/1001371.svg',
    'vue': 'https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg'
}