import React, { Component } from 'react';
import classnames from 'classnames';
import PieChart from 'react-minimal-pie-chart';
import MomentStyles from './Moment.module.css';
import Projects from './Projects';
import ProjectInfo from './ProjectInfo';
import ProjectStyles from './Projects.module.css';
import ImageZoom from 'react-medium-image-zoom';
import UtilitiesStyles from '../utilities.module.css';
import Nav from '../Nav';
import Progress from './Progress';



export default class Moment extends Component {
  render() {
    return (
      <Projects
        img="/assets/images/momentintro.png"
        title="Moment"
        timeline="May 2018 - April 2020"
        color="#A052FF"
        people={<p>Me, CEO, Developers</p>}
        roleList={['UX Designer', 'Front End Developer', 'Graphic Designer']}
        tools={['xd', 'ai', 'code']}
        subtitle="Moment removes user data silos by combining live chat, session recording, sales pipelines,
         and lead generation all into one application so companies can help their customers better."
        size="90%"
        bgClass={MomentStyles.bg}
      >

        <div id="overview">
        <ProjectInfo
          title="Moment"
          roles="UI Design • UX Design • Front End Development"
          timeline="May 2018 - April 2020"
          problem="Existing live chats aren't personal and make users feels like a number rather than a valued customer"
          outcome="Moment has a live chat feature that makes the customer-supporter experience more enjoyable. I re-designed the front end of the live chat and built a designer system for the entire product."
          description="Moment was born from the need for better customer support. The company I worked for was using Intercom for support, zendesk for help center, skype/hangouts for calling, sometimes Gmail for conversations. This led to data silos where user information was trapped on different platforms and no easy way to consolidate the data and make useful decisions. Intercom was also getting very expensive. <b>There was a need to find a way to combine all the customer interaction from end to end</b>. Although moment gives the user sales pipelines, CRM work spaces, lead generation and analytics, for this project, I focused mainly on the live chat feature."
       />
        </div>
        <div id="research" className={ProjectStyles.basic_margin}>
        <div className={ProjectStyles.title_flex}>
            <h1>Why do we need a Live Chat?</h1>
        </div>
        
        <div className={classnames(UtilitiesStyles.d_flex, UtilitiesStyles.text_center)}>
          <div className={UtilitiesStyles.flex_one}>
          <div style={{width: 100, margin: '30px auto'}}>
          <PieChart
            startAngle={270}
            data={[
              { title: 'One', value: 41, color: '#0074FF' },
              { title: 'Two', value: 59, color: '#aecdff' },
            ]}
          />
          </div>
            <h3>41%</h3>
            <p>Of people prefer live chat for getting help (Kayako)</p>
          </div>
          <div className={UtilitiesStyles.flex_one}>
            <div style={{width: 100, margin: '30px auto'}}>
            <PieChart
              startAngle={270}
              data={[
                { title: 'One', value: 79, color: '#0074FF' },
                { title: 'Two', value: 21, color: '#aecdff' },
              ]}
            />
            </div>
            <h3>79%</h3>
            <p>Of people prefer live chat because it is quicker (EConsultancy)</p>
          </div>
  
          <div className={UtilitiesStyles.flex_one}>
            <div style={{width: 100, margin: '30px auto'}}>
            <PieChart
              startAngle={270}
              data={[
                { title: 'One', value: 82, color: '#0074FF' },
                { title: 'Two', value: 18, color: '#aecdff' },
              ]}
            />
            </div>
            <h3>82%</h3>
            <p>Live chat has a 82%-92% satisfaction rate (Zendesk)</p>
          </div>
  
        
        </div>

      <div className={MomentStyles.container}>
        <div className={ProjectStyles.title_flex}>
            <h1>Problem Space</h1>
        </div>
        <p>When I started this project, there was already a lot of work already done for moment and the live chat in terms of development and design. In fact, it looked like this</p>
        <ImageZoom
            image={{
              src: '/assets/images/momentchatold.png',
              className: 'img',
              style: { width: '100%', marginBottom: 50 }
            }}
            zoomImage={{
              src: '/assets/images/momentchatold.png',
            }}
          />
          <p>So when it came time to market Moment and try to get customers, there was a glaring flaw. We had customers who liked what the product did but they cared about the aesthetics of their site and wanted something that would match their brand.</p>
        <div className={ProjectStyles.quote_container}>
          <div className={ProjectStyles.quote} style={{marginTop: 50}}>
          The application seems useful, but [the live chat is] ugly. <br/> I don’t want to put it on my site. 
          </div>
          <div className={ProjectStyles.source}>
          ⁠⁠  <p>—A Potential Moment Customer</p>
          </div>
        </div>

        <p>With this idea in mind, I sat down and took a look at the competitors to get a better understanding of the market. I scoured the web for each of the top competitors and tried to find how people were interacting with it. I wanted to know the thoughts and feelings of those who were looking for live chats.</p>
          <div className={ProjectStyles.table}>

          <table>
            <tr>
              <th>Competitor</th>
              <th>Features</th>
              <th>Pros</th>
              <th>Cons</th>
            </tr>
            <tr>
              <td><img src="https://blog.useproof.com/wp-content/uploads/2018/07/Intercom_Logo_Horizontal_Color.png" alt="Intercom Logo" width="150px"/></td>
              <td>
                <li>Help center</li>
                <li>System status</li>
                <li>Chat bot</li>
              </td>
              <td>'...I like that Intercom has enabled me to create a unique experience for visitors to our web page ...'</td>
              <td>'...One thing our business really needs is the ability to set away messages...but there's no option for us to temporarily close to go to a meeting...'</td>
            </tr>
            <tr>
            
            <td><img src="https://picksaas.com/storage/app/uploads/public/5c8/b7f/58e/thumb_2313_0x200_0_0_auto.png" alt="Crisp Chat Logo" width="100px"/></td>
            <td>
                <li>Help center</li>
                <li>Calling in chat</li>
                <li>Continue in (messenger, email, twitter etc)</li>
              </td>
              <td>'...* Seamless transition between live chat and email * Beautiful UI design * Highly efficient workflow * Extensive API * Apps available on all platforms...'</td>
              <td>'...There's no way to have separate conversations with the same customer...' <br/> '...Need more infos on the customers...'</td>
            </tr>
            <tr>
              <td><img src="https://www.bestlivechatsoftware.com/uploads/logos/tawkto-logo.png" alt="TawkTo Logo" width="100px"/></td>
              <td>
                <li>Live chat</li>
                <li>Chat Bubble decorations</li>
              </td>
              <td>'...monitor website activities and traffic in terms of number of visitors at a given time, page views, and daily site report...'</td>
              <td>'..deceptive...saying...hello we are online for instant help...even when there are no agents to respond to a visitor’s query..' </td>
            </tr>

          </table>
          </div>


          </div>
        </div>

          <div id="interpretation" className={ProjectStyles.basic_margin}>
          <div className={ProjectStyles.title_flex}>
            <h1>Interpretation</h1>
          </div>
          <p>After talking to <b>small business owners</b> and <b>guerilla testing on friends</b>, I realized there were 2 personas. One is our customer, the people who pay for Moment (which I call users) and the others are regular people who will end up using Moment live chat (which I call end users).</p>

          <ImageZoom
            image={{
              src: '/assets/images/momentPer1.png',
              className: 'img',
              style: { width: '48%', marginBottom: 20, marginRight: 20, boxShadow: '0px 0px 20px rgb(212, 215, 219)', borderRadius: 20 }
            }}
            zoomImage={{
              src: '/assets/images/momentPer1.png',
            }}
          />
          <ImageZoom
            image={{
              src: '/assets/images/momentPer2.png',
              className: 'img',
              style: { width: '48%', marginBottom: 20, boxShadow: '0px 0px 20px rgb(212, 215, 219)', borderRadius: 20 }
            }}
            zoomImage={{
              src: '/assets/images/momentPer2.png',
            }}
          />
        <div className={UtilitiesStyles.text_center}>
          <ImageZoom
            image={{
              src: '/assets/images/momexperience.png',
              className: 'img',
              style: { width: '50%', margin: '30px auto'}
            }}
            zoomImage={{
              src: '/assets/images/momexperience.png',
            }}
          />
        </div>
            <div className={ProjectStyles.title_flex}>
            <h1>Areas for improvement</h1>
          </div>
          <div className={classnames(UtilitiesStyles.d_flex, MomentStyles.insight_cont)}>
            <div className={classnames(UtilitiesStyles.flex_1, MomentStyles.insight)}>
            <p className={MomentStyles.title}>Unique & friendly Customer Experience</p>
            <p>Users wanted a more friendly, unique and customizable customer experience for their own users</p>
            <div className={MomentStyles.number}>1</div>
            </div>
            <div className={classnames(UtilitiesStyles.flex_1, MomentStyles.insight)}>
            <p className={MomentStyles.title}>Knowing wait time for reply</p>
            <p>Users wanted end users to know about wait time and end users wanted to know the wait time</p>
            <div className={MomentStyles.number}>2</div>
            </div>
            <div className={classnames(UtilitiesStyles.flex_1, MomentStyles.insight)}>
            <p className={MomentStyles.title}>Quick & Meaningful conversation</p>

            <p>End users wanted to get useful help quickly. Conversations in live chat were more fulfilling for end users.</p>
            <div className={MomentStyles.number}>3</div>
            </div>

            
          
          </div>
      </div>
          <div id="ideating" className={ProjectStyles.basic_margin}>

      <div className={ProjectStyles.title_flex}>
            <h1>Ideating + Wireframing</h1>
        </div>
          <div className={classnames(UtilitiesStyles.d_flex, MomentStyles.ideas)}>
            <div className={classnames(UtilitiesStyles.flex_1, MomentStyles.insight_ideas)}>
            <p className={MomentStyles.title}>Friendly & unique chat</p>
            <ul>
            <li>Rounded corners to show bubbliness, friendliness and fun.</li>
            <li>Adding supporter pictures gives the chat a human characteristic.</li>
            </ul>
      
            </div>
            <div className={classnames(UtilitiesStyles.flex_1, MomentStyles.insight_ideas)}>
            <p className={MomentStyles.title}>Knowing wait time for reply</p>
            <ul>
              <li>Creation of away mode that changes state of chat if supporters are away</li>
            </ul>
            </div>
            <div className={classnames(UtilitiesStyles.flex_1, MomentStyles.insight_ideas)}>
            <p className={MomentStyles.title}>Quick & Meaningful Help</p>

            <ul>
              <li>Making sure the chat is open and ready for messages easily</li>
            </ul>
            </div>

            
          
          </div>


      <ImageZoom
            image={{
              src: '/assets/images/momentwireframes.jpg',
              className: 'img',
              style: { width: '50%', marginBottom: 50, marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/momentwireframes.jpg',
            }}
          />
      

      <ImageZoom
            image={{
              src: '/assets/images/momentplan.jpg',
              className: 'img',
              style: { width: '50%', marginBottom: 50, marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/momentplan.jpg',
            }}
          />
          </div>
      

      <div id="lofi" className={ProjectStyles.basic_margin}>
        <h1>Lo-fi wireframes</h1>

      <div className={UtilitiesStyles.d_flex}>
          <ImageZoom
              image={{
                src: '/assets/images/momentDesign3.png',
                className: 'img',
                style: { width: '100%', marginBottom: 50 , marginTop: 50 }
              }}
              zoomImage={{
                src: '/assets/images/momentDesign3.png',
              }}
            />
      </div>

        <div className={ProjectStyles.title_flex}>
            <h1>Design Changes V1</h1>
        </div>

        <div className={classnames(UtilitiesStyles.d_flex, UtilitiesStyles.flex_center, MomentStyles.changes)}>
          <div className={UtilitiesStyles.flex_1} style={{marginRight: 50}}>
          <div className={MomentStyles.changes_title}>
            <h6>Design Change 1</h6>
            <h3>New Conversation Button</h3>
          </div>
          <p>Users were unable to find the plus button. It was intended to mimic google's floating button but it caused confusion. So it was changed to a button with text.</p>
          </div>
          <div className={UtilitiesStyles.flex_1}>
          <ImageZoom
                image={{
                  src: '/assets/images/momchange1.png',
                  className: 'img',
                  style: { width: '100%', marginBottom: 50 , marginTop: 50 }
                }}
                zoomImage={{
                  src: '/assets/images/momchange1.png',
                }}
              />
          </div>
        </div>
        <div className={classnames(UtilitiesStyles.d_flex, UtilitiesStyles.flex_center, MomentStyles.changes)}>
          <div className={UtilitiesStyles.flex_1} style={{marginRight: 50}}>
          <div className={MomentStyles.changes_title}>
            <h6>Design Change 2</h6>
            <h3>Away Mode Response</h3>
          </div>
          <p>We needed a way to give feedback to users and let them know we received their message while we were gone and that they would get a response via email.</p>
          </div>
          <div className={UtilitiesStyles.flex_1}>
          <ImageZoom
                image={{
                  src: '/assets/images/momchange2.png',
                  className: 'img',
                  style: { width: '100%', marginBottom: 50 , marginTop: 50 }
                }}
                zoomImage={{
                  src: '/assets/images/momchange2.png',
                }}
              />
          </div>
        </div>
        </div>
        <div id="hifi" className={ProjectStyles.basic_margin}>

      <div className={ProjectStyles.title_flex}>
          <h1>Visual Design</h1>
      </div>
      <p>From this mock up and a UI kit that I made using AdobeXD, I redesigned the first iteration of the chat using <b>ReactJS and HTML/CSS and pushed to production.</b></p>
      <ImageZoom
        image={{
          src: '/assets/images/mmomentuikit.png',
          className: 'img',
          style: { width: '100%', marginBottom: 50, borderRadius: 10 }
        }}
        zoomImage={{
          src: '/assets/images/mmomentuikit.png',
        }}
      />
      <ImageZoom
        image={{
          src: '/assets/images/momentoldish.png',
          className: 'img',
          style: { width: '100%', marginBottom: 50, borderRadius: 10 }
        }}
        zoomImage={{
          src: '/assets/images/momentoldish.png',
        }}
      />

      <p>Moment is customizable. People needed to be able to <b>change the color and style of their chat to match their brand</b>. For this, we made the blue changeable. This means the text has to adapt to the color in order to be legible. <b>I looked up the WCAG 2.0 guideline for color contrast </b>and made sure the text color would change from white to black at the correct value for it to be accessible.</p>
      <ImageZoom
        image={{
          src: '/assets/images/momentcustom.png',
          className: 'img',
          style: { width: '100%', marginBottom: 50, borderRadius: 10 }
        }}
        zoomImage={{
          src: '/assets/images/momentcustom.png',
        }}
      />
      <div className={ProjectStyles.title_flex}>
            <h1>Design Iteration One: Feedback</h1>
        </div>

      <p>Since Moment can record user interaction and sessions, I used this and feedback from our support team to user test periodically. We received valuable information about how users used this tool, here is some of the feedback:</p>

      <ImageZoom
            image={{
              src: '/assets/images/momentfeedback.png',
              className: 'img',
              style: { width: '100%', marginBottom: 50, borderRadius: 10 }
            }}
            zoomImage={{
              src: '/assets/images/momentfeedback.png',
            }}
      />

      <div className={ProjectStyles.title_flex}>
            <h1>Design Iteration Two</h1>
        </div>

        <p>This time I created a user flow to better understand what users should see when they interact with the live chat. How first mistake was not thoroughly understanding the flow that the user would have to go through in each and every situation. With the new flow done, I created more and more mockups to play around with and visualize.</p>
        <ImageZoom
            image={{
              src: '/assets/images/userflow.png',
              className: 'img',
              style: { width: '100%', marginBottom: 50, borderRadius: 10 }
            }}
            zoomImage={{
              src: '/assets/images/userflow.png',
            }}
      />
        <ImageZoom
            image={{
              src: '/assets/images/momentnewwork.png',
              className: 'img',
              style: { width: '100%', marginBottom: 50, borderRadius: 10 }
            }}
            zoomImage={{
              src: '/assets/images/momentnewwork.png',
            }}
      />

        <div className={ProjectStyles.title_flex}>
            <h1>Design Iteration Two</h1>
        </div>

        <div className={classnames(UtilitiesStyles.d_flex, UtilitiesStyles.flex_center, MomentStyles.changes)}>
          <div className={UtilitiesStyles.flex_1} style={{marginRight: 50}}>

          <div className={MomentStyles.changes_title}>
            <h6>Design Change 1</h6>
            <h3>New Conversation Update</h3>
          </div>
          <ul className={MomentStyles.dech}>
            <li>The logo space was made bigger so users can represent their brand better</li>
            <li>I read about colour theory to change the colors to a analogous colour scheme for a more vibrant and fun look</li>
            <li>If the end user's name is known, we will address them by it for a more welcoming feel</li>
          </ul>
          </div>
          <div className={UtilitiesStyles.flex_1}>
          <ImageZoom
                image={{
                  src: '/assets/images/mchnew1.png',
                  className: 'img',
                  style: { width: '100%', marginBottom: 50 , marginTop: 50 }
                }}
                zoomImage={{
                  src: '/assets/images/mchnew1.png',
                }}
              />
          </div>
        </div>
        <div className={classnames(UtilitiesStyles.d_flex, UtilitiesStyles.flex_center, MomentStyles.changes)}>
          <div className={UtilitiesStyles.flex_1} style={{marginRight: 50}}>
          
          <div className={MomentStyles.changes_title}>
            <h6>Design Changes 2</h6>
            <h3>New Intermediate Screen</h3>
          </div>
            <p>If the user has existing conversations, this screen shows so they can access them. By adding a bit of friction in starting a conversation, end users were better able to find conversations and supporters didn't have the repeated conversation creation issue anymore</p>
          </div>
          <div className={UtilitiesStyles.flex_1}>
          <ImageZoom
                image={{
                  src: '/assets/images/mchnew2.png',
                  className: 'img',
                  style: { width: '100%', marginBottom: 50 , marginTop: 50 }
                }}
                zoomImage={{
                  src: '/assets/images/mchnew2.png',
                }}
              />
          </div>
        </div>
        <div className={classnames(UtilitiesStyles.d_flex, UtilitiesStyles.flex_center, MomentStyles.changes)}>
          <div className={UtilitiesStyles.flex_1} style={{marginRight: 50}}>
          <div className={MomentStyles.changes_title}>
            <h6>Design Changes 3</h6>
            <h3>Inline Asking of Email</h3>
          </div>
          <p>Instead of a new page for asking for email, the option to enter email was given inline so as not to interrupt the end user flow. Adding identification sometimes deters people so this option still allows people to ask for help and for the company to provide great service.</p>
          </div>
          <div className={UtilitiesStyles.flex_1}>
          <ImageZoom
                image={{
                  src: '/assets/images/mchnew3.png',
                  className: 'img',
                  style: { width: '100%', marginBottom: 50 , marginTop: 50 }
                }}
                zoomImage={{
                  src: '/assets/images/mchnew3.png',
                }}
              />
          </div>
        </div>
        </div>

        <div id="final" className={ProjectStyles.basic_margin}>


        <div className={ProjectStyles.title_flex} style={{marginTop: 30}}>
            <h1>Final Design</h1>
        </div>

        <p>The designs have been coded in <b>React and HTML/CSS</b>. I also created the front page for Moment, including all illustrations. You can check out the live version by clicking the button below.</p>
        <div style={{ textAlign: 'center', marginTop: 40 }}><a href="https://www.momentcrm.com" target="_blank" rel="noopener noreferrer" className={UtilitiesStyles.button}>Live Version</a></div>

        <div className={UtilitiesStyles.flex_1}>
          <ImageZoom
                image={{
                  src: '/assets/images/momhome.png',
                  className: 'img',
                  style: { width: '100%', marginBottom: 50 , marginTop: 50 }
                }}
                zoomImage={{
                  src: '/assets/images/momhome.png',
                }}
              />
          </div>


        <div className={ProjectStyles.title_flex}>
            <h1>Other features not mentioned</h1>
          </div>
          <p>In order to address the needs of the people who do not like live chat, Moment also has a <b>built in calling system </b>as well as an <b>email system</b> to make sure no matter which way people like to communicate, it is taken care of.</p>
        </div>
          <div id="reflect" className={ProjectStyles.basic_margin}>

        <div className={ProjectStyles.title_flex}>
            <h1>Lessons Learned</h1>
          </div>
          <div className={classnames(UtilitiesStyles.d_flex, MomentStyles.insight_cont)}>
            <div className={classnames(UtilitiesStyles.flex_1, MomentStyles.insight)}>
            <p className={MomentStyles.title}>Test for long term use</p>
            <p>The main issue we faced was users who came back to Moment and looked for existing chats. I should have tested long term use</p>
            <div className={MomentStyles.number}>1</div>
            </div>
            <div className={classnames(UtilitiesStyles.flex_1, MomentStyles.insight)}>
            <p className={MomentStyles.title}>Get feedback from everyone</p>
            <p>The live chat is for everyone from young to old. In the next iteration, I will test it on older people.</p>
            <div className={MomentStyles.number}>2</div>
            </div>
            <div className={classnames(UtilitiesStyles.flex_1, MomentStyles.insight)}>
            <p className={MomentStyles.title}>The user is the biggest stakeholder</p>

            <p>We're building for people. They have control over the success of the product. We must make sure they are well taken care of.</p>
            <div className={MomentStyles.number}>3</div>
            </div>
            </div>

            <div className={ProjectStyles.title_flex}>
            <h1>Future Work</h1>
 
          </div>

            <p>There are some issues with moment's navigation. The meatball menu is confusing for people and the close button in mobile is on the wrong side of the screen which has been confusing people. In the next iteration, I will address that. 
            </p>
            <p>I've done quite a bit of other work for Moment that is not shown here, so if you're curious, send me a message and I'd love to discuss it with you!
            </p>
            </div>
          <Nav items={['overview', 'research', 'interpretation', 'ideation', 'lofi', 'hifi', 'final', 'reflect']}>
            <li><a href="#overview">Overview</a></li>
            <li><a href="#research">Research</a></li>
            <li><a href="#interpretation">Interpretation</a></li>
            <li><a href="#ideating">Ideation</a></li>
            <li><a href="#lofi">LoFi Wireframes</a></li>
            <li><a href="#hifi">HiFi Prototype</a></li>
            <li><a href="#final">Interactive</a></li>
            <li><a href="#reflect">Reflection</a></li>
          </Nav>
          <Progress/>


    </Projects>





    );
  }
}

