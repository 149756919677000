import React, { Component } from "react";
import classnames from "classnames";
import InsturaStyles from "./Instura.module.css";
import Projects from "./Projects";
import ProjectInfo from "./ProjectInfo";
import ProjectStyles from "./Projects.module.css";
import ImageZoom from "react-medium-image-zoom";
import UtilitiesStyles from "../utilities.module.css";
import LazyLoad from "react-lazy-load";
import Nav from "../Nav";
import Progress from "./Progress";
import Title from "./Title";
 
export default class InsturaFull extends Component {
  render() {
    return (
      <Projects
        img="/assets/images/ins.webp"
        title="Instura"
        timeline="Dec 2019"
        roleList={["UX Designer", "UX Researcher"]}
        tools={['xd', 'maze', 'fig', 'ai']}
        subtitle="A mobile app and web extension that helps makeup lovers combat consumerism and use more of their makeup"
        size="80%"
        color="#ffd600"
      >
        <div id="overview">
          <ProjectInfo
            title="Instura"
            
            problem="How might we help makeup lovers use more of their collection
                  and avoid impulse purchases?"
            outcome="85% of testers
                    agreed that the dupes feature would deter them from buying
                  similar colours. 70% of testers said the news feed would help them
                  use their collection more."
            description={
              <p>
                With companies releasing similar makeup every few
                months and the fear of missing out that comes with following
                beauty gurus on social media, women are finding it harder than
                ever to curb impulse spending and avoid consumerism. This can
                lead to unused makeup and over spending.{" "} 
                <b>
                  How might we help makeup lovers use more of their collection
                  and avoid impulse purchases?
                </b> I started this personal project to try and solve this problem.
              </p>
            }
          />
        <div className={classnames(ProjectStyles.basic_margin)}>
            <div className={InsturaStyles.context}>
              <div>
                <Title
                  title="Context"
                  subtitle="Providing Empathy" />
                <p className={InsturaStyles.context_inner}>Here is every single Anastasia Beverly Hills palette with a few of the brighter colours removed. They all look the same, don't they? Individually, the palettes might look different but if the colours were placed beside each other like this, it becomes harder to ignore the similarities. This is a huge problem for makeup lovers!</p>
              </div>
              <div>
                <ImageZoom
                  image={{
                    src: "/assets/images/abh.jpg",
                    className: "img",
                    style: { width: "100%", marginBottom: 20 }
                  }}
                  zoomImage={{
                    src: "/assets/images/abh.jpg"
                  }}
                />
              </div>
            </div>
            <div style={{ flex: '0 0 30%' }}>

            </div>


        </div>
        <div className={classnames(InsturaStyles.final)}>
            <div
              className={classnames(
                UtilitiesStyles.d_flex,
                UtilitiesStyles.flex_center,
                ProjectStyles.basic_margin,
                InsturaStyles.fin_text
              )}
            >
              <div className={UtilitiesStyles.flex_1}>
                <Title
                  title="Digital Makeup Collection"
                  subtitle="01" />


                <div className={InsturaStyles.text}>
                <h5>Problem</h5>
                <p>
                  When women are shopping, they don't always remember the exact
                  makeup shades and finishes they own. It should be easier to know
                  exactly what makeup is in one's collection without having to look through
                  bulky palettes.
              </p>
                <h5>Solution</h5>
                <p>
                  Instura will help users quickly import makeup they own. This can be a
                  cumbersome process and thus images were added (recognition vs recall) and allowed bulk importing items
                  by company. User testers rated this import feature a <b>4.5 out of 5 for how easy it is to import</b>.
              </p>
                </div>
              </div>
              <div>
                <img src="/assets/onboard1.gif" className={InsturaStyles.phone_gif} alt="Gif of on boarding on the app"/>
              </div>
            </div>
            <div
              className={classnames(
                UtilitiesStyles.d_flex,
                UtilitiesStyles.flex_center,
                ProjectStyles.basic_margin,
                InsturaStyles.fin_text
              )}
            >
              <div className={InsturaStyles.fin_text_first}>
                <img src="/assets/newsfeed1.gif" className={InsturaStyles.phone_gif} alt="gif of news feed on the app"/>
              </div>

              <div className={UtilitiesStyles.flex_1}>
                <div className={InsturaStyles.text}>
                  <Title
                    title="Curated Makeup Feed"
                    subtitle="02" />
                <h5>Problem</h5>
                <p>
                  Social media creates a sense of FOMO that urges people to buy
                  more. It also forces people to focus on the next shiny new thing
                  and forget the makeup they already own.
              </p>
                <h5>Solution</h5>
                <p>
                  Instura features a curated news feed that only shows the user
                  posts that only have makeup they own. The intention is to help people
                  get excited about the makeup they own and combat the fear of
                  missing out. <b>70% of testers said the news feed would help them
                  use their collection more.{" "}</b>
                </p>
                </div>
              </div>
            </div>

            <div
              className={classnames(
                UtilitiesStyles.d_flex,
                UtilitiesStyles.flex_center,
                ProjectStyles.basic_margin,
                InsturaStyles.fin_text
              )}
            >
              <div className={classnames(UtilitiesStyles.flex_1, InsturaStyles.fin_text_first)}>
                <div className={InsturaStyles.text}>
                  <Title
                    title="Dupes"
                    subtitle="03" />
                <h5>Problem</h5>
                <p>
                  Makeup companies are continually releasing new makeup that is very
                  similar to makeup that already exists. It can also be easy
                  to forget one's own collection when they are enthralled by new makeup.
              </p>
                <h5>Solution</h5>
                <p>
                  The dupes feature will allow users to find similar makeup shades
                  in their collection for makeup they want to buy. <b>85% of testers
                    agreed that the dupes feature would deter them from buying
                  similar colours.</b>
              </p>
              </div>
              </div>
              <div>
                <img src="/assets/dupes1.gif" className={InsturaStyles.phone_gif} alt="gif for dupes on the app"/>

              </div>
            </div>

            <div
              className={classnames(
                UtilitiesStyles.d_flex,
                UtilitiesStyles.flex_center,
                ProjectStyles.basic_margin,
                InsturaStyles.fin_text
              )}
            >
              <div>
                <img src="/assets/collection1.gif" className={InsturaStyles.phone_gif} alt="gif for makeup collection on the app"/>

              </div>
              <div className={classnames(UtilitiesStyles.flex_1, InsturaStyles.fin_text_first)}>
                <div className={InsturaStyles.text}>
                  <Title
                    title="Makeup Visualization"
                    subtitle="04" />
                <h5>Problem</h5>
                <p>
                  Users described a need to sort and maintain makeup. It was also
                  evident during the research phase that taking inventory and
                  seeing visualizations helped users see how much they were
                  spending.
              </p>
                <h5>Solution</h5>
                <p>
                  Provide a makeup visualization system with colour so they can
                  getting a better perspective of what they own without the
                  distraction of fancy packages.
              </p>
              </div>
              </div>
            </div>

            <div
              className={classnames(
                UtilitiesStyles.d_flex,
                UtilitiesStyles.flex_center,
                ProjectStyles.basic_margin,
                InsturaStyles.fin_text
              )}
            >
              <div className={classnames(UtilitiesStyles.flex_1, InsturaStyles.fin_text_first)} style={{marginTop: '50px'}}>

                <div className={InsturaStyles.text} style={{width: '100%', maxWidth: '100%'}}>
                  <Title
                    title="Web Extension popup"
                    subtitle="05" />
                <h5>Problem</h5>
                <p>
                  Although the app is aimed at finding dupes, the problem
                  of impulsive purchases still exists. The app won’t help if
                  they aren’t looking at it.
              </p>
                <h5>Solution</h5>
                <p>
                  The mobile app works in tandem with a web extension to deter impulse purchases just in time. When users look at makeup online, the extension pops up with dupes of that makeup item already in their collection. This feature would deter the user from adding the makeup item to the cart.
              </p>
              </div>

                <div style={{ marginTop: '50px' }}>
                  <img src="/assets/pop.gif" className={InsturaStyles.web_gif} alt="gif for web extension popup on the app" />
                </div>

              </div>
            </div>
        </div>
        </div>


        <div className={ProjectStyles.basic_margin}>

          <Title
            title="The Process"
            subtitle="An overview" />
            <ImageZoom
              image={{
                src: "/assets/images/iprocess.png",
                className: "img",
                style: { width: "100%", marginBottom: 20 }
              }}
              zoomImage={{
                src: "/assets/images/iprocess.png"
              }}
            />
        </div>
        <div id="observations" className={classnames(InsturaStyles.observations)}>
          <div className={ProjectStyles.basic_margin}>
            <Title
              title="Observations"
              subtitle="Seeing How it's done"/>
          <p>
            In order to understand why makeup was so enticing and what tactics companies used to sell products, I visited Sephora and
            observed how makeup was displayed, what makeup people they looked at and
            what kinds of questions people were asking friends as well as
            Sephora employees{" "}
          </p>

          <div
          >
            <div
              className={classnames(
                UtilitiesStyles.flex_one,
                InsturaStyles.flex_one,
                UtilitiesStyles.d_flex,
                InsturaStyles.ob_images
              )}
            >
              <div>
              <ImageZoom
                image={{
                  src: "/assets/images/isph1.png",
                  className: InsturaStyles.observe,
                }}
                zoomImage={{
                  src: "/assets/images/isph1.png"
                }}
              />
              </div>
              <p>
                Makeup was beautifully displayed so people could swatch colours.
                All makeup was lit up and highlighted to showcase them better.
                Signs for sales were everywhere and things out of stock didn’t mean you
                couldn’t buy it. They’d ship it for free.
              </p>
            </div>
            <div
              className={classnames(
                UtilitiesStyles.flex_one,
                InsturaStyles.flex_one,
                UtilitiesStyles.d_flex,
                InsturaStyles.ob_images
              )}
            >
            <div>
              <ImageZoom
                image={{
                  src: "/assets/images/isph2.png",
                  className: InsturaStyles.observe,
                }}
                zoomImage={{
                  src: "/assets/images/isph2.png"
                }}
              />
              </div>
              <p>
                I observed 5 people asking for help with foundation shades. It
                seems that people are unsure about colours that matched them the
                most. Friends discussed beauty gurus who tried the makeup they
                looked at and asked if they have tried it too.
              </p>
            </div>

            <div
              className={classnames(
                UtilitiesStyles.flex_one,
                InsturaStyles.flex_one,
                UtilitiesStyles.d_flex,
                InsturaStyles.ob_images
              )}
            >
              <div>
              <ImageZoom
                image={{
                  src: "/assets/images/isph3.png",
                  className: InsturaStyles.observe,
                }}
                zoomImage={{
                  src: "/assets/images/isph3.png"
                }}
              />
            </div>
              <p>
                All makeup had a “try me” sample. It was enticing and allowed
                people to play around with makeup before they bought it. Sephora
                also provides refunds for any reason. This makes it easier to
                justify buying makeup now.
              </p>
            </div>
          </div>
          </div>
        </div>
        <div id="word" className={ProjectStyles.basic_margin}>
            <Title
              title="Word Frequency Count"
              subtitle="Quantifying Importance" />
          <div>
            <p>
              I immersed myself in the makeup world by joining subreddits and
              communities that revolve around makeup. I started noticing the
              same words being used over and over so I decided to write a python script
              that counted the number of time the words appeared in comments in
              the top 1000 posts of r/makeuprehab
            </p>
            <ImageZoom
              image={{
                src: "/assets/images/iwfcount.png",
                className: "img",
                style: { width: "100%", marginBottom: 20 }
              }}
              zoomImage={{
                src: "/assets/images/iwfcount.png"
              }}
            />
          </div>
        </div>
        <div
          id="survey"
          style={{ marginBottom: 100 }}
          className={ProjectStyles.basic_margin}
        >
            <Title
              title="Surveying 38 People"
              subtitle="Quantifying Data" />
          <p>In order to gather quantitative data, I created a survey that was complete by 38 makeup lovers. These results pointed me towards to basic direction of the problem.</p>

          <div
            className={classnames(
              UtilitiesStyles.d_flex,
              UtilitiesStyles.text_center,
              InsturaStyles.sur_stats,
            )}
          >
            <div
              className={classnames(
                UtilitiesStyles.flex_one,
                InsturaStyles.flex_one,
              )}
            >
              <h3 className={InsturaStyles.stat}>44%</h3>
              <p className={InsturaStyles.research} width="200px">
                Of surveyed makeup lovers buy makeup multiple times a month
              </p>
            </div>
            <div
              className={classnames(
                UtilitiesStyles.flex_one,
                InsturaStyles.flex_one
              )}
            >
              <h3 className={InsturaStyles.stat}>81%</h3>
              <p className={InsturaStyles.research} width="200px">
                Of surveyed makeup lovers are motivated to buy makeup because of
                the colours
              </p>
            </div>

            <div
              className={classnames(
                UtilitiesStyles.flex_one,
                InsturaStyles.flex_one
              )}
            >
              <h3 className={InsturaStyles.stat}>41%</h3>
              <p className={InsturaStyles.research} width="200px">
                Of surveyed makeup lovers are swept up by the hype and buy on
                impulse
              </p>
            </div>
            <div
              className={classnames(
                UtilitiesStyles.flex_one,
                InsturaStyles.flex_one
              )}
            >
              <h3 className={InsturaStyles.stat}>72%</h3>
              <p className={InsturaStyles.research} width="200px">
                Of surveyed makeup lovers agree that spending money makes them
                feel better
              </p>
            </div>
          </div>
          <ImageZoom
            image={{
              src: "/assets/images/iin1.png",
              className: InsturaStyles.bar_graph
            }}
            zoomImage={{
              src: "/assets/images/iin1.png"
            }}
          />
          <ImageZoom
            image={{
              src: "/assets/images/iin2.png",
              className: InsturaStyles.bar_graph_2
        }}
            zoomImage={{
              src: "/assets/images/iin2.png"
            }}
          />
        </div>
        <div id="interview" className={InsturaStyles.interview}>
          <div className={ProjectStyles.basic_margin}>
              <Title
                title="Interviews"
                subtitle="Qualitative Data" />
          <p>
            Once I had a general idea about the pain points from the survey, I wanted to gather deeper insight into the problem. 
            I interviewed 15 people for this project. 8 of them were self
            proclaimed makeup & shopping addicts, 5 of them were just regular
            makeup lovers and 2 were professionals in the e-commerce field. Here
            are a few quotes.
          </p>

          <div
            className={classnames(
              UtilitiesStyles.d_flex,
              UtilitiesStyles.flex_column
            )}
          >
            <div
              className={classnames(
                UtilitiesStyles.flex_1,
                UtilitiesStyles.d_flex,
                UtilitiesStyles.flex_end,
                InsturaStyles.interview_basic
              )}
              style={{ margin: "20px 0px" }}
            >
              <div>
                <img
                  src="/assets/images/person1.png"
                  className={InsturaStyles.person}
                  alt="makeup addict"
                />
              </div>
              <div>
                <p className={InsturaStyles.quote}>
                  “The searching for the "perfect" item, the checkout, the "out
                  for delivery" message and the mail man handing me the package
                  all create a big excitement. Once the box has been open, more
                  often than not I tuck the items aside and don't move them
                  again for awhile. Clearly I don't need any of it, and
                  literally the shopping part is what the problem is.”
                </p>
                <p className={classnames(UtilitiesStyles.float_right)}>
                  - Makeup & shopping addict
                </p>
              </div>
            </div>
            <div
              className={classnames(
                UtilitiesStyles.flex_1,
                UtilitiesStyles.d_flex,
                UtilitiesStyles.flex_end,
                InsturaStyles.interview_basic
              )}
              style={{ margin: "20px 0px" }}
            >
              <div>
              <img
                src="/assets/images/person2.png"
                className={InsturaStyles.person}
                alt="makeup lover"
              />
              </div>
              <div>
                <p className={InsturaStyles.quote}>
                  “My makeup is constantly growing although I try to keep it
                  under control. As it is growing it is harder to keep track the
                  shades I own and prevent getting dupes. ”
                </p>
                <p className={classnames(UtilitiesStyles.float_right)}>
                  - Makeup lover
                </p>
              </div>
            </div>
            <div
              className={classnames(
                UtilitiesStyles.flex_1,
                UtilitiesStyles.d_flex,
                UtilitiesStyles.flex_end,
                InsturaStyles.interview_basic
              )}
              style={{ margin: "20px 0px" }}
            >
              <div>
              <img
                src="/assets/images/person3.png"
                className={InsturaStyles.person}
                alt="e-commerce professional"
              />
              </div>
              <div>
                <p className={InsturaStyles.quote}>
                  “Ongoing sales. ''Don't miss out!'' and ''Limited sales today
                  only!'' and ''Outlet!'' etc will give you the idea that you
                  have to make quick decisions, which is when people tend to buy
                  more than planned/necessary because it was on sale."
                </p>
                <p className={classnames(UtilitiesStyles.float_right)}>
                  - E-commerce professional
                </p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div id="compete" className={ProjectStyles.basic_margin}>
            <Title
              title="Competitive Research"
              subtitle="Understanding Solutions" />
          <p>
            To understand solutions already on the market in order to understand the existing market and the gaps in the industry, I decided to conduct
            competitive research and found that temptalia was the number 1
            solution on the market but even they had cumbersome processes for importing and maintaining makeup.{" "}
          </p>

          <ImageZoom
            image={{
              src: "/assets/images/icr.png",
              className: "img",
              style: { width: "100%", marginTop: 50 }
            }}
            zoomImage={{
              src: "/assets/images/icr.png"
            }}
          />
        </div>
        <div id="aff" className={ProjectStyles.basic_margin}>
          <div className={InsturaStyles.container}>
              <Title
                title="Affinity Diagram"
                subtitle="Organizing Data" />
            <p>
              With the collected data, I created an affinity diagram to understand how to prioritize the pain points that arose. The biggest issues ended up being: similar colours, social media, coping mechanism and consumerism.
            </p>
            <ImageZoom
              image={{
                src: "/assets/images/iad.jpg",
                className: "img",
                style: { width: "100%" }
              }}
              zoomImage={{
                src: "/assets/images/iad.jpg"
              }}
            />
          </div>
          <div>
              <Title
                title="Personas"
                subtitle="Empathizing with users" />
            <p>
              I found that 2 main user groups existed. One was a shopping addict and had trouble with impulse purchases while the other liked makeup and wanted
              a curated collection but didn't impulse purchase. Having made these persona, It was clear that the needs of both were very similar.
            </p>

            <ImageZoom
              image={{
                src: "/assets/images/ipr1.png",
                className: "img",
                style: { width: "50%", margin: "20px auto" }
              }}
              zoomImage={{
                src: "/assets/images/ipr1.png"
              }}
            />
            <ImageZoom
              image={{
                src: "/assets/images/ipr2.png",
                className: "img",
                style: { width: "50%", margin: "20px auto" }
              }}
              zoomImage={{
                src: "/assets/images/ipr2.png"
              }}
            />
          </div>
          <div>
              <Title
                title="Journey Map"
                subtitle="Looking for pain points" />
            <p>
             Through the lens of the personas, I created a journey map to visualize the paint points as well as bright spots in their journey. This helped me understand where in the journey a viable solution could be placed.
            </p>
            <ImageZoom
              image={{
                src: "/assets/images/ijm.png",
                className: "img",
                style: { width: "100%", margin: " auto" }
              }}
              zoomImage={{
                src: "/assets/images/ijm.png"
              }}
            />
          </div>
        </div>

        <div id="hmw" className={InsturaStyles.hmw}>
          <div className={ProjectStyles.basic_margin}>
              <Title
                title="How might we..."
                subtitle="Setting Goals" />
            <div
              className={classnames(
                UtilitiesStyles.d_flex,
                InsturaStyles.insight_cont
              )}
            >
              <div
                className={classnames(
                  UtilitiesStyles.flex_1,
                  InsturaStyles.insight
                )}
              >
                <p>
                  "...help the makeup lover combat consumerism and save more
                  money?"
                </p>
              </div>
              <div
                className={classnames(
                  UtilitiesStyles.flex_1,
                  InsturaStyles.insight
                )}
              >
                <p>
                  "... help the makeup lover deal with the fear of missing out?"
                </p>
              </div>
              <div
                className={classnames(
                  UtilitiesStyles.flex_1,
                  InsturaStyles.insight
                )}
              >
                <p>
                  "...help the makeup lover use their current collection of
                  makeup more?"
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="sketches" className={InsturaStyles.sketches}>
          <div className={ProjectStyles.basic_margin}>
              <Title
                title="Sketches"
                subtitle="Trying out ideas" />
          <div>
            <p>
              At this point, I started sketching potential solution but ended up
              getting stuck when I decided to add stats about makeup. I wasn’t
              sure if I should put it under profile or under “My Collection” I
              decided to perform card sorting to see if there was a better way
              to display the information.{" "}
            </p>
          </div>
          <div>
            <ImageZoom
              image={{
                src: "/assets/images/isketches.png",
                className: "img",
                style: { width: "100%" }
              }}
              zoomImage={{
                src: "/assets/images/isketches.png"
              }}
            />
          </div>
          </div>
        </div>
        <div id="card" className={InsturaStyles.sketches}>
          <div className={ProjectStyles.basic_margin}>

              <Title
                title="Card Sorting"
                subtitle="Building the IA" />
          <div>
            <p>
              First, I performed an <b>open card sorting test </b>with 3 people
              to see general groupings. Then I performed a{" "}
              <b>closed card sorting test</b> with 5 people to solidify my
              ideas. The flow chart to the right is the final information
              architecture.
            </p>
            <ImageZoom
              image={{
                src: "/assets/images/shahin.png",
                className: "img",
                style: { width: "50%" }
              }}
              zoomImage={{
                src: "/assets/images/shahin.png"
              }}
            />
          </div>
          <div>
            <ImageZoom
              image={{
                src: "/assets/images/iflow.png",
                className: "img",
                style: { width: "100%" }
              }}
              zoomImage={{
                src: "/assets/images/iflow.png"
              }}
            />
          </div>
          </div>
        </div>
        <div id="lofi">
          <div className={InsturaStyles.lofi}>
            <div className={ProjectStyles.basic_margin}>
              <div className={ProjectStyles.title_flex}>
                <Title
                  title="Lo-Fi Wireframes"
                  subtitle="Rapid Iterations" />
              </div>
            
            <div>
              <p>
                Once I knew where to put the makeup stats, I created low
                fidelity wireframes for the app and the web extension. Due the
                importance of colour in makeup, I made sure that the screen with
                makeup actually showed colour so the user testers had a better
                understanding of what the app did
              </p>
            </div>

            <ImageZoom
              image={{
                src: "/assets/images/ilofi.png",
                width: '100%'
              }}
              zoomImage={{
                src: "/assets/images/ilofi.png"
              }}
            />
          </div>
          </div>

          <div
            className={classnames(
              ProjectStyles.basic_margin,
              InsturaStyles.section_margin
            )}
          >

            <div className={ProjectStyles.title_flex}>
              <Title
                title="Usability Test Results"
                subtitle="Getting Feedback" />
            </div>
            <p>
              I performed usability tests with 5 representative users. Each
              person was given 6 tasks to complete. After this, they were asked
              to complete a System Usability Scale (SUS) questionnaire.{" "}
            </p>
            <div
              className={classnames(ProjectStyles.table, InsturaStyles.table)}
            >
              <table>
                <tbody>
                <tr>
                  <th></th>
                  <th>User#1</th>
                  <th>User#2</th>
                  <th>User#3</th>
                  <th>User#4</th>
                  <th>User#5</th>
                </tr>
                <tr>
                  <td>Sign up</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Add Natasha <br /> Denona palette
                  </td>
                  <td>✓</td>
                  <td>Had trouble finding the search bar</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Find post using Subculture</td>
                  <td>✓</td>
                  <td>Didn’t notice the “your makeup”</td>
                  <td>Didn’t notice the “your makeup”</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Find dark brown shade in your collection</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>Find dupes for Modern Renaissance</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>"which shade is mine?"</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <td>
                    Find stats about <br /> makeup usage
                  </td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>“How does it know How much I used?”</td>
                  <td>✓</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={classnames(InsturaStyles.sus)}>
            <div className={ProjectStyles.basic_margin}>
              <div
                className={classnames(
                  UtilitiesStyles.d_flex,
                  UtilitiesStyles.text_center,
                  InsturaStyles.stats_list,
                  UtilitiesStyles.flex_wrap
                )}
              >
                <div className={classnames(UtilitiesStyles.flex_1)}>
                  <div style={{ width: 100, margin: "30px auto" }}></div>
                  <h3 className={InsturaStyles.stat}>90.5</h3>
                  <p className={InsturaStyles.research}>SUS Score</p>
                </div>

                <div className={classnames(UtilitiesStyles.flex_1)}>
                  <div style={{ width: 100, margin: "30px auto" }}></div>
                  <h3 className={InsturaStyles.stat}>A+</h3>
                  <p className={InsturaStyles.research}>Grade</p>
                </div>
                <div className={classnames(UtilitiesStyles.flex_1)}>
                  <div style={{ width: 100, margin: "30px auto" }}></div>
                  <h3 className={InsturaStyles.stat}>96th</h3>
                  <p className={InsturaStyles.research}>Percentile</p>
                </div>
              </div>
                <Title
                  title="System Usability Scale (SUS) questionnaire"
                  subtitle="Quantifying Usability" />
              <p>
                These score were a strong indicator that the app was heading in
                the right direction. The test results for finding stats were
                especially validating because stats were put into the profile
                after the card sorting test and every tester got it right. Each
                of the test results were also above the average 68 score, which
                was also a good sign. These results were strong enough that I
                moved on with creating a high fidelity prototype.
              </p>
            </div>
          </div>
        </div>
        <div id="hifi">
          <div
            className={classnames(
              ProjectStyles.basic_margin,
              InsturaStyles.section_margin
            )}
          >
              <Title
                title="Visual System"
                subtitle="Setting up the style guide" />
            <ImageZoom
              image={{
                src: "/assets/images/ivs.png",
                className: "img",
                style: { width: "100%" }
              }}
              zoomImage={{
                src: "/assets/images/ivs.png"
              }}
            />
          </div>

          <div className={InsturaStyles.lofi}>
            <div className={ProjectStyles.basic_margin}>
            <div>
                <Title
                  title="Hi-Fi Wireframes"
                  subtitle="Building More Depth" />
              <p>
                Using the visual design system, I created a high fidelity
                prototype as shown on the right. I did a few guerrilla tests as
                I created the prototype to ensure I was fixing issues the
                previous usability tests showed. I then tested the prototype
                using Maze with 22 total testers.
              </p>
            </div>

            <ImageZoom
              image={{
                src: "/assets/images/ihighfi.png",
                width: '100%'
              }}
              zoomImage={{
                src: "/assets/images/ihighfi.png"
              }}
            />
            </div>
          </div>

          <div
            className={classnames(
              ProjectStyles.basic_margin,
              InsturaStyles.section_margin
            )}
          >

          <Title
            title="Maze Testing"
            subtitle="Mass Usability Testing" />
            <p>
              In order to further validate my designs, get more accurate feedback and to test with more users, I created a test using <a href="https://maze.design/" rel="noopener noreferrer" target="_blank">maze.design</a> and gave a link for the test to
              representative users. 60 people started the task and 31 people
              completed it. I had to go through the tests and remove testers who
              left the screen and didn’t attempt the test at all to get a better
              understanding of the usability. Here are the results!
            </p>

            <div
              className={classnames(
                ProjectStyles.table,
                InsturaStyles.table,
                InsturaStyles.table_stats
              )}
            >
              <table>
                <tbody>
                <tr>
                  <th></th>
                  <th># of testers</th>
                  <th>Success Rates</th>
                  <th>Notes</th>
                </tr>
                <tr>
                  <td>Sign up</td>
                  <td>60</td>
                  <td>97%</td>
                  <td>The tester may not have known they couldn’t type</td>
                </tr>
                <tr>
                  <td>
                    Add Natasha <br /> Denona palette
                  </td>
                  <td>57</td>
                  <td>97%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Find post using Sunset</td>
                  <td>47</td>
                  <td>95%</td>
                  <td>Clicking the card itself</td>
                </tr>
                <tr>
                  <td>Find dupes for "Love Letter" - Modern Renaissance</td>
                  <td>37</td>
                  <td>70%</td>
                  <td>
                    Users that failed couldn’t get past the first page didn’t
                    try to navigate using the footer
                  </td>
                </tr>
                <tr>
                  <td>Find dark brown shade in your collection</td>
                  <td>31</td>
                  <td>78%</td>
                  <td>Same as above</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className={classnames(InsturaStyles.sus)}>
            <div className={ProjectStyles.basic_margin}>
              <div
                className={classnames(
                  UtilitiesStyles.d_flex,
                  UtilitiesStyles.text_center,
                  InsturaStyles.stats_list,
                  UtilitiesStyles.flex_wrap
                )}
              >
                <div className={classnames(UtilitiesStyles.flex_1)}>
                  <div style={{ width: 100, margin: "30px auto" }}></div>
                  <h3 className={InsturaStyles.stat}>85%</h3>
                  <p className={InsturaStyles.research} width="200px">
                    Of testers said the dupes feature would deter them from
                    buying similar colours
                  </p>
                </div>

                <div className={classnames(UtilitiesStyles.flex_1)}>
                  <div style={{ width: 100, margin: "30px auto" }}></div>
                  <h3 className={InsturaStyles.stat}>4.5π</h3>
                  <p className={InsturaStyles.research} width="200px">
                    Average rating for ease of <br />
                    importing their whole collection
                  </p>
                </div>
                <div className={classnames(UtilitiesStyles.flex_1)}>
                  <div style={{ width: 100, margin: "30px auto" }}></div>
                  <h3 className={InsturaStyles.stat}>70%</h3>
                  <p className={InsturaStyles.research} width="200px">
                    Of testers said the news feed feature help them use their
                    makeup collection more
                  </p>
                </div>
              </div>
                <Title
                  title="Limitations"
                  subtitle="Issues with maze" />
              <p>
                Since the test was done unconventionally, it might have been
                harder for some users to know what to do. Regardless, a majority
                of the testers were successful and that is promising.
              </p>
            </div>
          </div>

          <div
            className={classnames(
              ProjectStyles.basic_margin,
              InsturaStyles.section_margin
            )}
          >
              <Title
                title="Maze Testing: Heat Maps"
                subtitle="Understanding microinteraction issues" />
            <p>
              Using the heatmaps provided by Maze, I was able to find minor
              interaction issues that I hadn’t noticed before. For example,
              users were clicking on the cards and pictures when they were
              tasked with getting information about what makeup the post offered
              instead of the little bar below. Some even tried to click the
              makeup.
            </p>

            <ImageZoom
              image={{
                src: "/assets/images/heatmap.png",
                className: "img",
                style: { width: "100%" }
              }}
              zoomImage={{
                src: "/assets/images/heatmap.png"
              }}
            />
          </div>
        </div>
        <div
          id="final">

          <ImageZoom
            image={{
              src: "/assets/images/ifinals.png",
              className: "img",
              style: { width: "100%", marginBottom: 20 }
            }}
            zoomImage={{
              src: "/assets/images/ifinals.png"
            }}
          />

          <div className={ProjectStyles.basic_margin}>
              <Title
                title="Interactive Prototype"
                subtitle="Try it out" />

            <p>
              I created this prototype by using Adobe XD. You can interact with it
              to see how it works! Adobe XD might load the screens incorrectly so
              you may have to go through the interaction twice to see it properly.{" "}
            </p>
            <div className={UtilitiesStyles.text_center}>
              <LazyLoad height={652} offsetVertical={200} >
                <iframe
                  title="Interactive Instura App"
                  importance="low"
                  loading="lazy"
                  className={UtilitiesStyles.prototype}
                  width="300"
                  height="640"
                  src="https://xd.adobe.com/embed/59f3e7ff-ce1c-4d71-6b98-f6d7c4ceec26-2fc2/?fullscreen"
                />
              </LazyLoad>
              <p>Or click below to see it in another tab</p>

              <a
                href="https://xd.adobe.com/embed/59f3e7ff-ce1c-4d71-6b98-f6d7c4ceec26-2fc2/?fullscreen"
                target="_blank"
                rel="noopener noreferrer"
                className={UtilitiesStyles.button}
              >
                High Fidelity Prototype
          </a>
            </div>


          </div>
         
        </div>
        <div id="feedback" className={ProjectStyles.basic_margin} style={{ marginTop: '12rem', marginBottom: '5rem' }}>

            <Title
              title="Concept Feedback"
              subtitle="is the idea valuable?" />
          <p>I posted my prototype and concept on r/makeup, r/makeupaddiction and r/makeuprehab and the feedback was amazing! Here are a few quotes!</p>

          <div className={InsturaStyles.concept_feedback}>
          <p>“I really really love this! I love the design of the interface, it's sleek and modern and that really makes it much more pleasant to use! I would definitely buy the app and its a great way to take stock of your inventory and also de clutter as you add things in!”</p>

          <p>“I love the sound of this! This is such a smart and innovative approach--I especially love the but[sic] about a curated feed that only shows what you have. Even when I seek out content from anti-consumerist creators, sometimes seeing something they own that I don't have can be tempting. I would absolutely be willing to test it out!”</p>

          <p>"I need that first solution right now. I started a no-buy last month and it's been going well, but I have SO much makeup that I forget what I have and am tempted to buy it again. One of the things helping me from making splurges from FOMO is reminding myself "Yeah, I don't have that ____ but I have that shade already and I haven't used it up yet". Having that information at my fingertips would be so awesome."</p>

          <p>"That sounds really interesting - I personally feel like one of the major issues is gurus always using new products for tutorials - I would like a system that specifically looked for posts on things I have. The inventory system sounds interesting"</p>
          <p>This sounds amazing! I love the idea of a social media newsfeed that only shows things already in your arsenal!!!</p>
          <p>“I love all the points you covered with your plan, especially the tailored feed to what we already own!”</p>
          </div>


        </div>
        <div id="reflect" className={InsturaStyles.reflect}>
          <div className={ProjectStyles.basic_margin}>

              <Title
                title="Next Steps"
                subtitle="How to go further" />
          <div className={UtilitiesStyles.d_flex}>
            <p className={classnames(UtilitiesStyles.flex_1, InsturaStyles.reflect_text)}>
              <b>More personalization</b> <br />
              Many testers mentioned the desire to add their own pictures to the
              swatches and a way to add personal reviews.
            </p>

            <p className={classnames(UtilitiesStyles.flex_1, InsturaStyles.reflect_text)}>
              <b>Expand to include other products</b>
              <br />A few testers mentioned the desire to track skincare along
              with makeup. It would be interesting to see how I could include
              those products and provide filtering. Skin care is a different
              issue with different needs and would require more user research.{" "}
            </p>
          </div>


              <Title
                title="Lessons Learned"
                subtitle="For next time" />
          <div className={UtilitiesStyles.d_flex}>
            <p className={classnames(UtilitiesStyles.flex_1, InsturaStyles.reflect_text)}>
              <b>Always Validate Assumptions</b> <br />I went into the project
              with a certain assumption about how people react to makeup and I
              am so glad that I did research because the real issues were
              different. The solution people loved the most was not the solution
              I had anticipated being popular.
            </p>

            <p className={classnames(UtilitiesStyles.flex_1, InsturaStyles.reflect_text)}>
              <b>Be Careful While Testing</b>
              <br />
              Using a new tool like Maze can skew the results because it adds
              another layer of complexity to the test that might confuse users.
              The statistics they provide had to be taken with a grain of salt.
              I might try doing more in person tests.{" "}
            </p>
          </div>
          </div>
        </div>
        <Nav
          items={[
            "overview",
            "observations",
            "word",
            "survey",
            "compete",
            "aff",
            "hmw",
            "sketches",
            "card",
            "lofi",
            "hifi",
            "final",
            "feedback",
            "reflect"
          ]}
        >
          <li>
            <a href="#overview">Overview</a>
          </li>
          <li>
            <a href="#observations">Observations</a>
          </li>
          <li>
            <a href="#word">Word Frequency</a>
          </li>
          <li>
            <a href="#survey">User Research</a>
          </li>
          <li>
            <a href="#compete">Competitive Research</a>
          </li>
          <li>
            <a href="#aff">Interpretation</a>
          </li>
          <li>
            <a href="#hmw">How Might We?</a>
          </li>
          <li>
            <a href="#sketches">Sketches</a>
          </li>
          <li>
            <a href="#card">Card Sorting</a>
          </li>
          <li>
            <a href="#lofi">LoFi Wireframes</a>
          </li>
          <li>
            <a href="#hifi">HiFi Prototype</a>
          </li>
          <li>
            <a href="#final">Final Solution</a>
          </li>
          <li>
            <a href="#feedback">Concept Feedback</a>
          </li>
          <li>
            <a href="#reflect">Reflection</a>
          </li>
        </Nav>
        <Progress/>
      </Projects>
    );
  }
}
