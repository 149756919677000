import React from 'react';
import FooterStyles from './Footer.module.css'
import 'react-alice-carousel/lib/alice-carousel.css'

export default class Footer extends React.Component {

  renderMoreProjects = () => {
    return(
      <div className={FooterStyles.more_projects}>
        <div className={FooterStyles.more_projects_inner}>
        <h1>Check out more projects!</h1>

        <div className={FooterStyles.contain}>
          <a href="/projects/potluck" className={FooterStyles.card} style={{ backgroundColor: "#6C69EA"}}>
            <img src="/assets/images/potluck.png" width='200' alt="Potluck Project" />
            </a>
          <a href="/projects/wish" className={FooterStyles.card} style={{ backgroundColor: "#2FB7EA"}}>
            <img src="/assets/images/wish.png" width='200' alt="Wish Project" />
            </a>
          <a href="/projects/instura" className={FooterStyles.card} style={{ backgroundColor: 'rgb(255, 214, 59)'}}>
            <img src="/assets/images/ins.png" width='200' alt="Instura Project" />
            </a>
          <a href="/projects/mento" className={FooterStyles.card} style={{ backgroundColor: 'rgb(26, 43, 82)'}}>
            <img src="/assets/images/mento.png" width='100' alt="Mento Project" />
          </a>

          <a href="/projects/schedu" className={FooterStyles.card} style={{ backgroundColor: 'rgb(65, 137, 255)'}}>
            <img src="/assets/images/schedu.png" width='150' alt="Schedu Project" />

          </a>
          <a href="/projects/moment" className={FooterStyles.card} style={{ backgroundColor: 'rgb(160, 82, 255)'}}>
            <img src="/assets/images/momentchat.png" width='150' alt="Moment Project" />

          </a>
          <a href="/projects/parsehub" className={FooterStyles.card} style={{ backgroundColor: 'rgb(255, 147, 150)'}}>
            <img src="/assets/images/parsehub.png" width='200' alt="Parsehub Project" />

          </a>
         
          <a href="/projects/walden" className={FooterStyles.card} style={{ backgroundColor: 'rgb(88, 174, 237)'}}>
            <img src="/assets/images/walden1.png" width='200' alt="Walden Project" />
          </a>
        </div>
        </div>


      </div>
    );
  }
  render() {
    return (
      <div>
        {window.location.href.includes('project') && this.renderMoreProjects()}
      <div className={FooterStyles.footer}>
        <div className={FooterStyles.container}>

        <a href="mailto:taamannae@gmail.com">
          <i className="fa fa-2x fa-envelope"></i></a>
        <a href="https://dribbble.com/taamannae" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-2x fa-dribbble"></i>
        </a>
        <a href="https://www.linkedin.com/in/taamannae/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-2x fa-linkedin-in"></i>
        </a>
        <a href="https://github.com/Taamannae" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-2x fa-github-alt"></i>
        </a>
        <a href="https://medium.com/@taamannae" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-2x fa-medium-m"></i>
        </a>
        </div>
        <p>Made with <i className="fa fa-code"></i> by me </p>
      </div>
      </div>
    );
  }
}