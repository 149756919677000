import React, { Component } from 'react';
import classnames from 'classnames';
import ProjectInfoStyles from './ProjectInfo.module.css';
import ProjectsStyles from './Projects.module.css';
import Title from './Title';

class ProjectInfo extends Component {
  render() {
    return (
        <div className={classnames(ProjectsStyles.basic_margin, ProjectInfoStyles.info)}>
        <div className={ProjectInfoStyles.tools}>
          <div>
            <Title title="The Story"/>
            {this.props.description}
          </div>
        </div>

        <div className={ProjectInfoStyles.prob_sol}>
          <div>
            <Title title="Problem" />
            {this.props.problem}
          </div>
          <div>
            <Title title="Outcome" />
            {this.props.outcome}
          </div>

        </div>
      </div>
    );
  }
}

export default ProjectInfo;
