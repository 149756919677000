import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import HeaderStyles from './Header.module.css'
import { withRouter } from "react-router";

class Header extends React.Component {
  render() {
    let active;
    let location = this.props.history.location.pathname.split('/')[1];

    if (location.includes("process")) {
      active = "process";
    } else if (location.includes("uichall")) {
      active = 'uichall';
    } else if (location.includes("illustrations") || location.includes("36daysoftype") || location.includes("misc")) {
      active = 'ill';
    } else if (location.includes("about")) {
      active = 'about';
    } else if (location.includes("publication")) {
      active = 'pub';
    } else if (location.includes("work") || location.includes("project") || location.includes("")) {
      active = "work"
    }
    return (
      <div className={HeaderStyles.navbar}>
          <Navbar collapseOnSelect expand="lg">
          <span className={HeaderStyles.container}>
            <Navbar.Brand href="/">
              <span className={HeaderStyles.brand}>
                <img className={HeaderStyles.brand_logo} alt="logo" src="/assets/images/logo.svg"/>
                  <span className={HeaderStyles.brand_title}>
                    Tammy
                  </span>
              </span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                <Nav>
                <Nav.Link href="/" className={active === "work" ? HeaderStyles.active : null}>Work</Nav.Link>
                <Nav.Link href="/process" className={active === "process" ? HeaderStyles.active : null}>My Process</Nav.Link>
                <Nav.Link href="/uichall" className={active === "uichall" ? HeaderStyles.active : null}>DailyUI</Nav.Link>
                <Nav.Link href="/illustrations" className={active === "ill" ? HeaderStyles.active : null}>Illustrations</Nav.Link>
                <Nav.Link href="/publications" className={active === "pub" ? HeaderStyles.active : null}>Articles</Nav.Link>
                <Nav.Link href="/about" className={active === "about" ? HeaderStyles.active : null}>About</Nav.Link>
                <Nav.Link href="/tammy_taabassum_resume.pdf" target="_blank">Resume</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </span>
            </Navbar>
      </div>
    );
  }
}

export default withRouter(Header)