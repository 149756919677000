import React, { Component } from 'react';
import TitleStyles from './Title.module.css';


export default class Title extends Component {
  render() {
    return (
      <div className={TitleStyles.title_container}>
        <div className={TitleStyles.title_container_inner}>
          {/* <div>
            <div className={TitleStyles.accent}>
              <div style={{ marginTop: "10px" }}>“</div>
            </div>
          </div> */}
          <div className={TitleStyles.title_quote}>
            <h5>{this.props.subtitle}</h5>
            <h2>{this.props.title}</h2>
          </div>
        </div>
      </div>
    );
  }
}

