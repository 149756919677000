import React, { Component } from 'react';
import classnames from 'classnames';
import ProjectsStyles from './Projects.module.css';
import _ from 'lodash';
import Icons from '../icons.js';

class Projects extends Component {
  render() {

    let extras = null;
    if (!this.props.noextra) {
      let roles = []
      _.forEach(this.props.roleList, function (value) {
        roles.push(<div key={value}>{value} </div>)
      });

      let tools = []
      _.forEach(this.props.tools, function (value) {
        tools.push(<img className={ProjectsStyles.tool_icon} key={value} src={Icons[value]} alt={value}/>)
      });

      let people;
      if (this.props.people) {
        people = (
          <div>
            <h1>team</h1>
            <div>{this.props.people}</div>
          </div>
        );

      }

      extras = (
        <div className={
          classnames(ProjectsStyles.quick_info)}>
          <div>
            <h1>My Role</h1>
            <div>{roles}</div>
          </div>
          {people}

          <div>
            <h1>Tools</h1>
            <div>{tools}</div>
          </div>

          <div>
            <h1>Timeline</h1>
            <div>{this.props.timeline}</div>
          </div>

          {this.props.slideDeck && (<div>
            <h1>Slide Deck</h1>
            <a href={this.props.slideDeck} className={ProjectsStyles.button} target="_blank" rel="noopener noreferrer">View Slide Deck</a>
          </div>)}

        </div>

      );
    }

    
    return (
      <div className={ProjectsStyles.project}>
        <div className={ProjectsStyles.inner}>
          <div style={{ flex: 1 }}>
            <h5>{this.props.timeline}</h5>
            <h1>{this.props.title} -</h1>
          </div>
          <div style={{ flex: 1 }}>
            <mark>{this.props.subtitle}</mark>
          </div>
        </div>

        <div className={
          classnames(
            ProjectsStyles.product_mock,
          )} style={{backgroundColor: this.props.color}}>
            <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img src={this.props.img} style={{ width: this.props.size ? this.props.size : null}} className={this.props.class} alt="Screenshot of project" />
          </div>

          <div className={ProjectsStyles.info}>
            {extras}
          </div>
        </div>
        <img src="/assets/images/hp1.svg" className={ProjectsStyles.hp1} alt=""/>
        <img src="/assets/images/hp2.svg" className={ProjectsStyles.hp2} alt=""/>
          {this.props.children}
      </div>
    );
  }
}

export default Projects;
