import React from 'react';
import MyProcessStyles from './MyProcess.module.css';
import FeatherIcon from 'feather-icons-react'


export default class Home extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      filters:[],
      textIdx: 0
    }
  }

  render() { 

    return (
      <div className={MyProcessStyles.container}>

        <img src="/assets/images/hp1.svg" className={MyProcessStyles.hp1} alt=""/>
        <img src="/assets/images/hp2.svg" className={MyProcessStyles.hp2} alt=""/>

        <div className={MyProcessStyles.title_container}>
          <div className={MyProcessStyles.title_container_inner}>
            <div>
              <div className={MyProcessStyles.accent}>
                <div style={{ marginTop: "10px"}}>“</div>
              </div>
              
            </div>
            <div className={MyProcessStyles.quote}>
              <h5>My Motto</h5>
              Design is the intent behind the outcome—so be intentional
            </div>
          </div>
        </div>
        <p>My design process is driven by 3 main things: <b>empathy</b>, <b>mindfulness</b>, <b>neuroscience</b>. I think design processes aren’t just about the methodologies but also, knowing when a tool is important to use. Each step should be connected to the pervious one and provide a reason why the next one is needed.</p>

        <div className={MyProcessStyles.concept_container}>
          <div>
            <FeatherIcon icon="heart" stroke="#384EDD" stokeWidth="4px"/> <br/>
            <h4>Empathy</h4>
            <p>I like to build with the user in mind at all times. If I can empathize, then I can advocate for a solution that people actually need.</p>
            
          </div>
          <div>
            <FeatherIcon icon="eye" stroke="#384EDD" stokeWidth="4px" /> <br/>
            <h4>Mindfulness</h4>
            <p>Mindfulness and intentionality go hand in hand. At every step of the process, I am mindful that I’m still solving the right problem and ensure all design decisions are deliberate.</p>

          </div>
          <div>
            <FeatherIcon icon="heart" stroke="#384EDD" stokeWidth="4px" /> <br/>
            <h4>Neuroscience</h4>
            <p>Did you know you can be habituated and less reactive to repetitive sounds? I use my Neuroscience major to build solutions backed by the human brain.</p>
          </div>
        </div>
        <hr/>
        <div className={MyProcessStyles.title_container}>
          <div className={MyProcessStyles.title_container_inner}>
            <div>
              <div className={MyProcessStyles.accent}>
                <div>‽</div>
              </div>

            </div>
            <div className={MyProcessStyles.quote}>
              <h5>Solving Problems</h5>
              My Design Process
            </div>
          </div>
        </div>

        <p>Every project is different and will have different methodologies. Some will need card sorting and some might have the information architecture already well defined. The nitty gritty method choice tends to differ but I still follow a general process for every project. In order to formulate solutions, I like to have 6 main phases: <b>Define</b>,<b> Discover</b>,<b> Interpret</b>,<b> Ideate</b>,<b> Prototype</b>, and <b>Test</b>. These phase are rarely ever linear and tend to be repeated multiple times before delivery.</p>

        <div className={MyProcessStyles.process_container}>
          <div>
            <FeatherIcon icon="list" stroke="#384EDD" stokeWidth="4px" /><br/>
            <h4>Define</h4>
            <p>The first step I take is usually to define the general business needs, requirements for the project, understand the deliverables, timeline and expectations. It's important to always stay on the same page with the rest of the team. At this step, I like to jot down my own hypothesis for what I expect the problem might be. That way, I can reflect on what happened at the end of the project.</p>
          </div>
          <div>
            <FeatherIcon icon="book-open" stroke="#384EDD" stokeWidth="4px" /><br/>
            <h4>Discover</h4>
            <p>During this phase, I just listen and learn. I collect information, talk to people and explore the problem space. I will also look into user reports, talk to team members about design decision and understand why the product came to be the way it is. Sometimes when user research isn't within the budget or allocated time, I'll try to conduct ad hoc guerilla interviews to avoid designing blindly. </p>
          </div>
          <div>
            <FeatherIcon icon="search" stroke="#384EDD" stokeWidth="4px" /><br/>
            <h4>Interpret</h4>
            <p>The interpretation phase allows me to define the users, their needs and pain points. Personas, journey maps, story boards and problem statements can be defined or redefined. At this stage, I set our goals and the metrics we want to hit for success. This phase is always very insightful. </p>
          </div>
          <div>
            <FeatherIcon icon="edit-2" stroke="#384EDD" stokeWidth="4px" /><br/>
            <h4>Ideate</h4>
            <p>The ideation phase is a lot of fun for me! I like to bring in developers, other designers, stake holders and customer supports to an ideation session to explore different options. I like to let my mind go wild and come up with as many solutions as I can. I strive for quantity over quality at this stage.</p>
          </div>
          <div>
            <FeatherIcon icon="layout" stroke="#384EDD" stokeWidth="4px" /><br/>
            <h4>Prototype</h4>
            <p>The prototype phase ties deeply with the testing phase. I start with simple sketches to get my ideas down and increase the fidelity through more and more iterations. Sometimes in complex projects, a wireframing tool won't be enough and I'll make a coded prototype. </p>
          </div>
          <div>
            <FeatherIcon icon="clipboard" stroke="#384EDD" stokeWidth="4px" /><br/>
            <h4>Test</h4>
            <p>During this phase, I'll try guerilla testing, usability testing, A/B testing, SUS testing etc. depending on what we want to learn. The testing phase tends to kick my butt more than any other. It's a humbling experience when the idea you were confident in fails. </p>
          </div>
        </div>
        <div>

          <div className={MyProcessStyles.title_container}>
            <div className={MyProcessStyles.title_container_inner}>
              <div>
                <div className={MyProcessStyles.accent}>
                  <div style={{marginTop: '-10px'}}>π</div>
                </div>

              </div>
              <div className={MyProcessStyles.quote}>
                <h5>How I like to work</h5>
                My Philosophies
            </div>
            </div>
          </div>
          <div className={MyProcessStyles.process_container}>


          <div>
          <FeatherIcon icon="code" stroke="#384EDD" stokeWidth="4px" /><br/>
          <h4>My Developer Hat</h4> <br/>
          With my background as a front-end engineer, I speak the language of the developers I work with. I think it is important to understand and empathize not only with your users but also with your team. Knowing how to code helps me understand not only the problems the developers on my team faces but I can also explain to them precisely what I want in the design.
            </div>
            <div>
          <FeatherIcon icon="globe" stroke="#384EDD" stokeWidth="4px" /><br/>
          <h4>Involve Everyone</h4> <br/>
          Designers should never work in silos. I try to bring in people who I think have the best insights. I talk to developers, product support agents, stake holders, users and user researchers and bring them into the project as early and as often as I can. I enjoy being collaborative and collecting insights from places one might not think to look.
          </div>
            <div>
          <FeatherIcon icon="globe" stroke="#384EDD" stokeWidth="4px" /><br/>
          <h4>Information comes from everywhere</h4> <br/>
          I like to find information from where ever I can and I think there is plenty of information to be found. This means joining supporters as they answer product questions to understand where users have problems or even scouring reviews of the product as well as competing projects to find insights.
          </div>
        </div>
        </div>


      </div>
    );
  }
}

