import React from 'react';
import HomeStyles from './Home.module.css';
import ProjectThumbnail from './ProjectThumbnail';
import Typist from 'react-typist';
import TypistLoop from 'react-typist-loop';
import Fade from 'react-reveal/Fade';

const PROJECTS = [

  {
    tags: ['ui', 'ux'],
    component: (
     <ProjectThumbnail
         key={9}
         location="/projects/Potluck"
         title="Potluck"
         roles="UX Design"
         size="70%"
         tools={['vue', 'pen', 'fig']}
        desc="A simpler product management tool. I improved the usability from 40% to 96% and was offered to stay on as a Product Designer."
        bgcolor="#6C69EA"
         class={HomeStyles.moment}
         image="/assets/images/potluck.png"
     />
   )
 },
  {
    tags: ['ui', 'ux'],
    component: (
      <ProjectThumbnail
        key={3}
        location="/projects/wish"
        title="Wish Case Study"
        roles="UX Design"
        size="60%"
        tools={['pen', 'maze', 'fig']}
        desc="A personal case study showcasing my process or trying to improving engagement on the e-commerce mobile app Wish."
        bgcolor="#2FB7EA"
        class={HomeStyles.moment}
        image="/assets/images/wish.png"
      />
    )
  }, 
  {
    tags: ['ui', 'ux'],
    component: (
     <ProjectThumbnail
         key={1}
         location="/projects/instura"
         title="Instura"
         roles="UX Design"
         size="50%"
         tools={['xd', 'maze', 'fig']}
         desc="A mobile app and web extension that helps makeup lovers combat consumerism and use more of their makeup"
         bgcolor="#FFD63B"
         class={HomeStyles.moment}
         image="/assets/images/ins.png"
     />
   )
 }, 
  {
    tags: ['ui', 'fe', 'ux'],
    component: (
     <ProjectThumbnail
         key={2}
         location="/projects/mento"
         title="Mento"
         roles="UX Design"
         size="30%"
        tools={['xd', 'ai', 'fig']}
        bgcolor="#1A2B52"
        desc="My Google 2020 UX Design Challenge. I matched with the YouTube Gaming team and made it to the offer review stage of the process. Unfortunately, due to COVID-19 the internship was canceled."
         class={HomeStyles.moment}
         image="/assets/images/mento.png"
     />
   )
  },
  {
    tags: ['ux', 'fe', 'ui'],
    component: (
      <ProjectThumbnail
        key={4}
        location="/projects/parsehub"
        title="Parsehub"
        id="ph"
        size="70%"
        tools={['xd', 'ai', 'ang']}
        desc="Parsehub is a point and click web scraping tool for non-coders to efficiently grab large amounts of web information."
        bgcolor="#FF9396"
        roles="Frontend Development • UX Design"
        image="/assets/images/parsehub.png"
      />
    )
  },
  {
    tags: ['ui', 'fe', 'ux'],
    component: (
      <ProjectThumbnail
        key={5}
        location="/projects/moment"
        title="Moment"
        roles="UX Design • Frontend Development"
        id="moment"
        tools={['xd', 'ai', 'code']}
        desc="Moment removes user data silos by combining live chat, session recording, sales pipelines, and lead generation all into one application"
        bgcolor="#A052FF"
        class={HomeStyles.moment}
        image="/assets/images/momentchat.png"
      />
    )
  },
  {
    tags: ['ui', 'fe', 'ux'],
    component: (
     <ProjectThumbnail
         key={6}
         location="/projects/schedu"
         title="Schedu"
         roles="UX Design"
         size="50%"
        tools={['xd', 'ai', 'pen']}
         bgcolor="#4189FF"
        desc="A mobile app that helps students lessen time management cognitive load, finish assignments on time & auto-rescheduling unfinished tasks."
         class={HomeStyles.moment}
         image="/assets/images/schedu.png"
     />
   )
 },
  {
    tags: ['ui', 'fe', 'ux'],
    component: (
     <ProjectThumbnail
         key={7}
         location="https://zetta.design"
         title="Zetta Design Studio"
         roles="UX Design"
         size="90%"
        tools={['xd', 'ai', 'fig']}
        bgcolor="#3A4FDD"
        desc="My jack of all trades design studio, specializing in branding, UX Design, Frontend Development, Illustrations, Webflow and more."
         class={HomeStyles.moment}
         image="/assets/images/zetta.svg"
     />
   )
 }, 
  {
    tags: ['fe', 'gd'],
    component: (
      <ProjectThumbnail
        key={8}
        oldProjects
      />
      )
    }

]

export default class Home extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      filters:[],
      textIdx: 0
    }
  }

  filter = (filter) => {
    let state = this.state.filters;
    if (state.includes(filter)) {
      var index = state.indexOf(filter);
      state.splice(index, 1);
    } else {
      state.push(filter);
    }
    this.setState({
      filters: state
    });
  }

  componentDidMount() {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 1500);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }


  renderProjects = (projects) => {
    let comp = [];
    for (let i = 0; i < projects.length; i++) {
      let project = projects[i];
      if (this.state.filters.every(v => project.tags.includes(v))) {
        comp.push(projects[i].component);
      }
    }
    return comp
  }


  render() { 

    return (
      <div className="Home">
        <div className={HomeStyles.jumbotron}>
        <div className={HomeStyles.info}>
            <Fade right>
          <img src="/assets/images/hp1.svg" className={HomeStyles.hp1} alt=""/>
          </Fade>

            <Fade left>

          <img src="/assets/images/hp2.svg" className={HomeStyles.hp2} alt=""/>
          </Fade>
            <Fade bottom>
            
            <h5 className={HomeStyles.intro_header}>Hi <span role="img" aria-label="waving hand">👋</span>! Welcome to my portfolio</h5>
            <div className={HomeStyles.text_bg}>
                <span className={HomeStyles.text_out}>I'm Tammy and I am </span> 
                <mark className={HomeStyles.text_change}>
                  <img src="/assets/images/mouse.svg" className={HomeStyles.mouse} alt=""/>

                <TypistLoop interval={200} style={{position: 'relative'}}>

                  {[
                    'a UX Designer',
                    'a Front-End Engineer',
                    'an Illustrator',
                  ].map(text => <Typist cursor={{show: false}} className={HomeStyles.typist} key={text}>{text} <Typist.Backspace count={text.length + 1} delay={1200} /></Typist>)}
                </TypistLoop></mark></div>

              <Fade bottom>

              <mark className={HomeStyles.subtext}>I am passionate about crafting accessible interactions & experiences through mindfulness, empathy & neuroscience</mark>
             </Fade>
              {/* <div className={HomeStyles.btns}>
                  <a href="/tammy_taabassum_resume.pdf" target="_blank" rel="noopener noreferrer" className={UtilitiesStyles.button}>
                Resume</a>
                <a href="mailto:taamannae@gmail.com" target="_blank" rel="noopener noreferrer" className={UtilitiesStyles.button}>
                Email Me</a>
              </div> */}
          </Fade>


        </div>
        </div>
        <div className={HomeStyles.content}>
          <Fade bottom distance="50px">

        
          <div className={HomeStyles.project_container}>
            {this.renderProjects(PROJECTS)}
          
          </div> 
        </Fade>
        </div>
      </div>
    );
  }
}

