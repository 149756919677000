import React from 'react';
import ProjectThumbnailStyles from './ProjectThumbnail.module.css';
import Nav from './containers/Nav';


export default class ProjectThumbnail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: true
    }
  }

  handleStateChange = () => {
    this.setState({
      isOpen: true
    })
  }

  render() {
    let img = "url(" + this.props.image + ")";

    if (this.props.oldProjects) {
      return (
        <a href="/old" className={ProjectThumbnailStyles.project_old} ref={this.myrefs} style={{ backgroundColor: "rgb(241, 242, 246)"}}>
          <mark style={{ zIndex: 3, fontWeight: 700 }}> <h3>Want to see more?</h3></mark>
          <mark style={{ zIndex: 3 }}><h6>Check out my older projects</h6></mark>
          <img src="/assets/images/hp1.svg" className={ProjectThumbnailStyles.hp1} alt=""/>
          <img src="/assets/images/hp2.svg" className={ProjectThumbnailStyles.hp2} alt=""/>
        </a>
      )
    }
    return (
      <a href={this.props.location} className={ProjectThumbnailStyles.project} ref={this.myrefs} style={{backgroundColor: this.props.bgcolor}}>
        <div className={ProjectThumbnailStyles.image}>
          <img src={this.props.image} width={this.props.size ? this.props.size : '50%'} alt={this.props.title + ' preview screenshot'}/>
        </div>
        <div className={ProjectThumbnailStyles.info}>

          <Nav
            isOpen={this.state.isOpen}
            idx={'4'}
            features={this.props.tools}
            onStateChange={this.handleStateChange} />
          <h3>{this.props.title}</h3>
          <p>{this.props.desc}

</p>
        </div>
      </a>
    );
  }
}

