import React, { Component } from 'react';
import UofTHacksStyles from './UofTHacks.module.css';
import Projects from './Projects';
import ProjectStyles from './Projects.module.css';
import ProjectInfo from './ProjectInfo';
import Progress from './Progress';

export default class UofTHacks extends Component {
  render() {
    return (
      <Projects
        img="/assets/images/uofthacks1.webp"
        title="UoftHacks"
        size={"70%"}
        roleList={['Web Developer', 'Graphic Designer']}
        color="#FF4B5A"
        tools={['pen', 'ai', 'code']}
        timeline="May 2015 - Jan 2019"
        subtitle="Led the development, design and product design teams. I created a cohesive logos, web designs, sponsorship packages"
        bgClass={UofTHacksStyles.bg}
      >
      <ProjectInfo
          title="UofTHacks Web & Graphic Design"
          timeline="May 2015 - Jan 2019"
          problem="UofTHacks needed new a branding identity, a landing page, hacker portal, promotional material and design leader."
          outcome="I Led the development, design and product design teams. I created a cohesive logos, web designs, sponsorship packages"
          description={(<p>UofTHacks is a student run hackathon at the University of Toronto. My responsibilities over the last 4 years were to <b>design cohesive branding, develop the website and lead developers</b></p>)}
       />

      <div className={ProjectStyles.basic_margin}>
        <div className={ProjectStyles.title_flex}>
          <h1>Logo Design</h1>
        </div>
        <p>I joined UoftHacks in 2015 as a designer. This was my first real experience with developing a real project from scratch. I was tasked with first creating a logo. The event had a previous logo but I wanted to create something simple, versatile and usable for a long time. I came up with a bunch of different ideas and design that I presented to my team. Some of them were too cute, or too big or not balanced enough. We really wanted to use our mascot Stan the Moose. He represents UofT and Canada, so my designs show various styles of moose.  </p>


          <img src="/assets/images/p11.png" width="100%" alt="logo test"/>
          <img src="/assets/images/p10.png" width="100%" alt="logo test"/>

        <div className={ProjectStyles.title_flex}>
          <h1>Logo Design</h1>
        </div>

          <img src="/assets/images/p12.png" width="30%" alt="final logo"/>

        <div className={ProjectStyles.title_flex}>
          <h1>Sponsorship Package</h1>
        </div>

          <img src="/assets/images/uofthacks_1.png" width="100%" alt="2016 package design final"/>
          <img src="/assets/images/p13.png" width="100%" alt="package design interations"/>
          <img src="/assets/images/p14.png" width="100%" alt="uofthacks 2016 package designs"/>

        <div className={ProjectStyles.title_flex}>
          <h1>Website Designs</h1>
        </div>
          <img src="/assets/images/uoft2017.png" width="100%" alt="uofthack 2017 design"/>

        <div className={ProjectStyles.title_flex}>
          <h1>Website Designs</h1>
        </div>
          <img src="/assets/images/site.png" width="100%" alt="website"/>
          <img src="/assets/images/prototype.png" width="100%" alt="prototype"/>
          <img src="/assets/images/package.png" width="100%" alt="uofthacks package"/>
    </div>
    <Progress/>
    </Projects>





    );
  }
}

